.fair-table-header {
  &__points {
    text-align: center;
    font-size: 20px;
    font-weight: normal;

    &--user {
      font-size: 30px;
    }
  }

  &__icons {
    display: flex;
    justify-content: flex-end;

    img {
      &:first-of-type {
        margin-right: 25px;
      }
    }
  }

  &__link-download {
    color: #ff2800;
    font-weight: bold;
    
    &:hover {
      color: #ff2800;
    }
  }
}