// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: 'General Sans', sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono',
    'Monaco', monospace !default;

/// Main Colors
$main-dark-grey: #303e3d;
$main-orange: #f08200;
$main-pink: #eb5087;
$main-green: #3ea400;
$brand-color: #e7672b;
$font-main-grey: #505050;

//Badge red
$badge-red: #d70000;
$badge-white: #fff;
$btn-pdf-bg-color: #d8231f;
$btn-grey-bg-color: #505050;
$btn-success-bg-color: #3ea400;
$btn-blue-bg-color: #009bd3;

$notification-background-color: #d70000;
$notification-font-color: #fff;

$notification-dialog-success-bg-color: #3ea400;
$notification-dialog-error-bg-color: #d70000;
$notification-dialog-font-color: #fff;

$brand-color-table-hover: rgb(251, 232, 223);

$footer-inverted-color: #ffffff;

$popover-badge-arrow-width: 30px;
$popover-badge-arrow-height: 15px;

$input-label-active-color: rgba(255, 255, 255, 0.5);
$checkbox-color: #000;

$table-datepicker-header-bg-color: #f3f3f3;
$table-striped-odd-color: #f4f4f4;

$dashboard-notification-color: #ffffff;

$form-error-color: #ff5656;

$product-counter-bg-color: #3ea400;

$product-box-background: #f5f5f5;

$campaign-item-bg-color: #f1f0f0;
$campaign-border-color: #bcbcbc;

$allergens-light-grey-border-color: #cdcaca;
$allergens-dark-grey-border-color: $main-dark-grey;

$recipes-item-box-border-color: #e4e4e4;
$recipe-details-bg-color: #fff;

$search-placeholder-text-color: #bebebe;

$my-account-new-password-placeholder-text-color: #a8a8a8;

$filters-box-bg-color: #f3f3f3;
$filters-header-color: #000;
$filters-chip-bg-color: #e4e4e4;

$border-grey: #dedede;

$delivery-status-colors: (
    'pending': #505050,
    'planned': #009bd3,
    'partially-delivered': #e7672b,
    'delivered': #3ea400,
    'cancelled': #d8231f,
);

$oscart-red: #e40068;
$oscart-blue: #004f86;

$breakpoints: (
    'small': (
        max-width: 1024px,
    ),
    'medium': (
        max-width: 1200px,
    ),
) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '../' !default;
