.discount-summary-box {
  background-color: $main-green;
  text-align: center;
  color: #FFFFFF;
  padding: 23px 49px 0;
  max-width: 265px;
  border-radius: 6px;

  &__title {
    font-size: 30px;
    margin-bottom: 0;
    font-weight: normal;
  }

  &__amount-label {
    font-size: 12px;
    margin-bottom: 4px;
  }

  &__amount {
    font-size: 37px;
    margin-bottom: 5px;
    font-weight: normal;
  }
}