.footer {
    font-size: 15px;
    color: $font-main-grey;
    letter-spacing: 0;
    text-align: center;
    margin-top: 200px;
    padding-bottom: 55px;

    a {
        color: $font-main-grey;
    }

    &__contact-info {
        margin-bottom: 22px;
        font-weight: 500;
    }

    &__icon {
        margin-bottom: 27px;
        width: 32px;
        height: 32px;
    }

    &--inverted {
        color: $footer-inverted-color;

        a {
            color: $footer-inverted-color;
        }
    }
}
