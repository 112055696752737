.product-slider {
  max-width: 1200px;
  margin: 0 auto 80px;
  overflow-x: hidden;

  .carousel-inner {
    overflow: auto;
  }

  .carousel-item {
    padding: 10px 0;
    display: flex !important;
    align-items: initial;
    justify-content: center;

    &.active {
      display: flex !important;
    }
  }

  &.carousel-fade {
    .carousel-item {
      display: flex !important;
    }
  }

  .card {
    overflow: visible;
  }

  .card-body {
    position: relative;
    padding: 1.25rem 0 0;
  }

  &.carousel {
    .carousel-control-next, .carousel-control-prev {
      width: 4%;
    }
    .carousel-control-prev-icon, .carousel-control-next-icon {
      filter: invert(100%);
      height: 53px;
      width: 51px;
      position: absolute;
    }

    .carousel-control-prev-icon {
      right: 0;
    }

    .carousel-control-next-icon {
      left: 0;
    }
    @media (max-width: 768px) {
      .carousel-control-prev-icon {
        right: -10px;
      }
  
      .carousel-control-next-icon {
        left: -10px;
      }
    }
  }

  &__item-box {
    padding: 10px;
    box-shadow: none;
    border: 1px solid #E4E4E4;
    border-radius: 0;
    max-width: 216px;
    margin-right: 10px;
    display: inline-flex;

    @media (max-width: 1200px) {
      max-width: 241px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &__image-wrapper {
      text-align: center;
      cursor: pointer;

      img {
        height: 160px;
        object-fit: cover;
      }
    }

    &__image-placeholder {
      background-color: #EDEDED;
      width: 187px;
      height: 177px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__body-wraper {
      padding: 1.25rem 1.25rem 85px;
    }

    &__title {
      font-size: 16px;
      text-align: center;
      min-height: 60px;
      cursor: pointer;
    }

    &__promotion {
      text-align: center;
      margin-top: 22px;
      //position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;

      &__text {
        font-size: 14px;
      }

      &__badge {
        display: inline-block;
        margin-left: 11px;
      }

      &__available-date {
        font-size: 12px;
        margin-bottom: 0;
        margin-top: 19px;
      }
    }
  }
}