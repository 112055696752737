.future-delivery {
    &__badge {
        border: none;
        border-radius: 0;
        box-shadow: none;
        display: inline-block;
        font-size: 15px;
        font-weight: normal;
        line-height: 1;
        padding: 6px 10px;
        text-align: center;
        vertical-align: baseline;
        white-space: nowrap;

        + .tooltip {
            .tooltip-inner {
                width: 240px;
                max-width: 240px;
            }
            .arrow::before {
                /*border-left-color: #e7672b !important;*/
                border-top-color: #e7672b;
            }
        }
    }

    &__popover {
        background-color: #e7672b;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        padding: 10px 15px;
        text-align: center;

        table {
            th {
                text-transform: uppercase;
                border: none;
                padding-bottom: 8px;
                white-space: nowrap;
                overflow: hidden;
            }
            td {
                border: none;
            }
        }
    }

    &__badge {
        z-index: 5;
        position: relative;
    }
}
