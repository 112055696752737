.badge {
    &--circle {
        line-height: 1.5;
        border-radius: 50%;
        font-size: 13px;
        width: 25px;
        height: 25px;
    }

    &--red {
        background-color: $badge-red !important;
    }

    &--white {
        background-color: $badge-white !important;
        color: $main-orange !important;
    }
}

.campaign-stickers {
    display: inline-block;
    margin-bottom: 10px;

    .campaign__stickers-title {
        margin-bottom: 16px;

        &.alignRight {
            text-align: right;
        }
    }

    .campaign__stickers-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        justify-content: end;
        direction: rtl;
        align-items: end;
        row-gap: 26px;
    }

    @media (max-width: 993px) {
        display: block;
        text-align: center;
    }

    .campaign__item__points.rectangle {
        display: inline-flex;
        margin: 0 2px 4px;
        width: 125px;
        height: 80px;

        .campaign__item__points__label a {
            font-size: 12px;
            font-weight: 500;
            text-decoration: underline;
        }

        .campaign__item__points__label a:hover {
            text-decoration: none;
        }

        .campaign__item__points__points {
            font-size: 30px;
            font-weight: 700;
        }
    }
}
