.quantity {
    text-align: center;

    &.showCount {
        justify-content: center;
    }

    &.alignRight {
        display: flex;
        justify-content: flex-start;
    }

    &.relative-container {
        position: relative;
    }

    &__icon {
        width: 30px;
        opacity: 1;
        transition: opacity 0.3s;
        cursor: pointer;

        &:hover {
            opacity: 0.5;
        }
    }

    &__input {
        width: 53px;
        height: 35px;
        text-align: center;
        /*background-color: transparent;
        border: none;*/
        outline: none;
        margin: 0 10px;
        /*&__active {*/
        background-color: white;
        border: 1px solid #d2d2d2;
        border-radius: 3px;
        /*}*/
    }

    &__count {
        border-radius: 18px;
        background-color: #e7672b;
        font-weight: 700;
        font-size: 18px;
        color: #fff;
        min-width: 48px;
        height: 35px;
        padding-top: 5px;
        display: inline-block;
        position: absolute;
        right: 13px;

        &__cart {
            right: auto;

            left: 13px;
        }

        &__align {
            right: 110px;
        }

        &.relative {
            position: relative;
            right: 0;
        }
    }

    .hidden {
        visibility: hidden;
    }
}
