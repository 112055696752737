.notification {
    color: $notification-font-color;

    &__title {
        font-weight: normal;
        margin-bottom: 1px;
    }

    &__text {
        font-weight: normal;
        margin-bottom: 0;
    }

    &__close {
        &:after {
            content: ' ';
            background-image: image('close-white.svg');
            height: 20px;
            width: 20px;
            position: absolute;
            top: 33px;
            right: 22px;
            cursor: pointer;
            background-size: 12px 12px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &--inline {
        background-color: $notification-background-color;
        margin-top: 25px;
        margin-bottom: 25px;
        padding: 12px 40px;
        border-radius: 2.25px;
        position: relative;
        animation-duration: 0.3s;

        .notification {
            &__title {
                font-size: 24px;
            }
            &__text {
                font-size: 15px;
            }
            &__url {
                color: white;
                text-decoration: underline;
            }
        }
    }

    &__dialog {
        color: $notification-dialog-font-color;
        max-width: 388px;

        &--error {
            .modal-content {
                background-color: $notification-dialog-error-bg-color;
            }
        }

        &--success {
            .modal-content {
                background-color: $notification-dialog-success-bg-color;
            }
        }

        &--warning {
            .modal-content {
                background-color: $brand-color;
            }
        }

        &__header {
            border-bottom: none;

            &__close {
                width: 15px;
                text-align: center;
                cursor: pointer;
                margin-right: 0;
                margin-left: auto;

                &__icon {
                    width: 10px;
                    height: 10px;
                }
            }
        }

        &__body {
            text-align: center;
            padding-bottom: 37px;

            &__icon {
                margin-bottom: 40px;
            }

            &__title {
                font-weight: normal;
                font-size: 24px;
                margin-bottom: 1rem;
            }

            &__text {
                font-size: 18px;
                font-weight: 300;
                margin-bottom: 1rem;

                a {
                    text-decoration: underline;
                    color: $notification-dialog-font-color;
                }

                .form-check-input[type='checkbox'] {
                    + label:before,
                    &:not(.filled-in) + label:after {
                        border-color: #fff !important;
                    }
                }

                label.btn input[type='checkbox'] {
                    + label:before,
                    &:not(.filled-in) + label:after {
                        border-color: #fff !important;
                    }
                }
            }
        }
    }
}

.modal-backdrop {
    &.show {
        opacity: 0.2;
    }
}
