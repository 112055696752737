.homepage {
    &__logo {
        margin-right: 46px;
    }

    &__navbar {
        background: linear-gradient(180deg, $main-orange 0%, $main-pink 100%);
        font-weight: normal;
        padding: 0;

        &__icon {
            width: 25px;
            height: 25px;
            //margin-right: 11px;
        }

        &__label {
            display: inline-block;
            vertical-align: middle;
            letter-spacing: 0.5px;
        }

        @media (max-width: 910px) {
            &__label {
                display: none;
            }
        }

        @media (max-width: 1180px) {
            z-index: 9999;

            .container {
                max-width: 100%;
            }

            &__label {
                font-size: 12px;
            }
        }

        .navbar-nav {
            height: 100px;
            display: flex;
            align-items: center;
            gap: 15px;

            .nav-item {
                border: 1px solid #ffffff6a;
                border-radius: 6px;

                &.nav-account {
                    border: none;
                    border-radius: 0;

                    .nav-link {
                        font-family: 'General Sans', sans-serif;
                    }
                }

                .nav-link {
                    color: #fff;
                    padding: 14px 18px 13px 16px;
                    font-size: 18px;
                    height: 100%;
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    font-family: 'Anton', sans-serif;

                    @include respond-to('medium') {
                        padding: 14px 6px 14px;
                    }
                }

                /*@media (max-width: 768px) {
            .nav-link {
                padding: 10px 6px 27px;
            }
        }*/
            }
        }

        @media (max-width: 768px) {
            .navbar-nav {
                margin: auto;
                height: 90px;
            }
        }

        .dropdown {
            .dropdown-toggle {
                gap: 12px;
                display: flex;

                &:after {
                    content: ' ';
                    margin-left: 21px;
                    border: none;
                    background: image('select-arrow-white.svg') no-repeat;
                    width: 12px;
                    height: 8px;
                }
            }

            .homepage__navbar__dropdown {
                display: grid;
                grid-template-columns: auto auto;
                grid-column-gap: 10px;
                grid-row-gap: 5px;
                background: linear-gradient(
                    180deg,
                    $main-pink 0%,
                    $main-orange 100%
                );
                padding: 10px 10px;
                border-radius: 10px;
                margin-top: 10px;
                border: 1px solid #fff;
                box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.4);

                a > button {
                    color: #fff !important;
                    border-radius: 5px;
                    font-family: 'Anton', sans-serif;
                    gap: 5px;
                    font-size: 18px;

                    &:hover {
                        background-color: #ffffff31 !important;
                        //lighter filter
                        filter: brightness(1.2);
                    }
                }
            }

            .dropdown-menu {
                a {
                    .dropdown-item {
                        display: flex;
                        align-items: flex-end;
                        padding: 15px 15px;
                        min-width: 200px;
                        align-items: center;
                        border-radius: 7px;

                        img {
                            height: 25px;
                        }

                        span {
                            position: relative;
                            bottom: -2px;
                            font-size: 18px;
                        }
                    }

                    .customer-dropdown-item {
                        white-space: normal;
                    }
                }
            }
        }

        .badge {
            font-family: 'General Sans', sans-serif;
        }

        @media (max-width: 768px) {
            .navbar-collapse {
                display: flex !important;
                flex-basis: auto;
            }

            .navbar-toggler {
                display: none;
            }
        }

        @media (min-width: 400px) {
            .navbar-nav {
                flex-direction: row;
            }
        }

        .container-fluid {
            padding: 0 140px;
        }
    }
}
