.ReactTable {
    border: none;

    .rt-thead {
        &.-header {
            box-shadow: none;

            .rt-th {
                // background-color: transparent;
                border-right: 1px solid transparent;
                text-align: center;
            }

            color: #000;
            font-size: 12px;
            font-weight: normal;
        }

        &.-filters {
            border-bottom: none;
            min-height: 50px;

            input {
                height: 35px;
                border-radius: 0;
                font-size: 15px;

                &[value=''] {
                    + .filter-clear {
                        display: none;
                    }
                }
            }

            .table__rt__col.table__rt__col--fix-width.table__rt__id {
                width: 100px !important;
                max-width: 100px !important;
            }

            .filters-title {
                font-size: 18px;
                background: #bcbcbc;
                color: #fff;
                padding-top: 25px;
                padding-bottom: 21px;
                margin-bottom: 0;
                padding-left: 12px;
                font-weight: normal;
                text-transform: uppercase;
            }

            .filters-close {
                float: right;
                width: 12px;
                margin: 8px 12px;
                cursor: pointer;
            }

            .filter-clear {
                position: absolute;
                right: 12px;
                top: 7px;
                cursor: pointer;
                height: 21px;
                width: 15px;
                text-align: center;

                img {
                    width: 10px;
                }
            }

            .rt-th {
                border-right: none;
                background: #bcbcbc;
                padding-bottom: 33px !important;
                padding-top: 0 !important;

                &:first-child {
                    padding-left: 12px;
                }
            }

            .rt-tr {
                background: #bcbcbc;
            }

            .table__rt__product-name {
                max-width: 500px;
            }
        }

        .rt-tr {
            text-align: left;

            .rt-th {
                padding: 23px 5px 20px;
                font-weight: 300;

                &:not(.disable-sort-indicator) {
                    > div {
                        &:after {
                            font-family: 'FontAwesome';
                            content: '\f0dc';
                            display: inline-block;
                            margin-left: 5px;
                            margin-top: 2px;
                            position: absolute;
                            bottom: 21px;
                        }
                    }

                    &.-sort-asc,
                    &.-sort-desc {
                        box-shadow: none;

                        > div {
                            &:after {
                                content: '';
                                background-size: contain;
                                display: inline-block;
                                margin-left: 5px;
                                margin-top: 2px;
                                position: absolute;
                            }
                        }
                    }

                    &.-sort-asc {
                        > div {
                            &:after {
                                font-family: 'FontAwesome';
                                content: '\f0dd';
                                bottom: 22px;
                            }
                        }
                    }

                    &.-sort-desc {
                        > div {
                            &:after {
                                font-family: 'FontAwesome';
                                content: '\f0de';
                                bottom: 18px;
                            }
                        }
                    }
                }
            }
        }
    }

    .rt-tbody {
        .rt-tr {
            line-height: 1.2;
            cursor: pointer;

            .rt-td {
                font-size: 15px;
                letter-spacing: 0;
            }
        }
    }

    &.-striped {
        .rt-tr {
            &.-odd {
                background: transparent;

                .rt-td {
                    background-color: $table-striped-odd-color;
                }
            }
        }
    }

    &.-fair-table {
        .rt-tr {
            &.-odd {
                background: transparent;

                .rt-td {
                    background-color: #f4f4f4;
                }
            }
        }
    }

    &.-highlight {
        .rt-tbody {
            .rt-tr {
                &:hover {
                    > .rt-td {
                        background-color: $brand-color-table-hover;
                    }

                    .delete-table-row {
                        &:after {
                            display: inline-block;
                        }
                    }
                }

                &:not(.-padRow) {
                    &:hover {
                        background-color: transparent;
                    }
                }
            }

            .rt-tr-group {
                .child {
                    .delete-table-row {
                        &:after {
                            display: initial;
                            right: -20px;
                        }
                    }
                }
            }
        }
    }

    .pagination-bottom {
        .-pagination {
            box-shadow: none;
            border-bottom: 2px solid rgba(0, 0, 0, 0.1);

            .-btn {
                font-size: 15px;
            }

            .-pageInfo {
                font-size: 15px;

                .-pageJump {
                    input {
                        color: $font-main-grey;
                    }
                }
            }

            .-pageSizeOptions {
                select {
                    display: block !important;
                    font-size: 15px;
                    color: $font-main-grey;
                }
            }
        }
    }
}

.table {
    &__header {
        margin-top: 30px;
        margin-bottom: 30px;

        &__search {
            cursor: pointer;
        }

        &__actions-col {
            margin-right: 20px;
            display: inline-block;
            position: relative;

            &--right {
                margin-left: auto;
            }
        }

        &__actions-col2 {
            margin-left: 20px;
            display: inline-block;
            position: relative;

            &--right {
                margin-left: auto;
            }
        }
    }

    .bold {
        font-weight: bold;
    }

    &--headHidden .rt-thead,
    &--bodyHidden .rt-tbody {
        display: none;
    }

    &__rt {
        &__quantity {
            min-width: 138px;
        }

        &--fixed-header {
            > .rt-table {
                > .rt-thead {
                    &.-header {
                        position: fixed;
                        top: 228px !important;
                        backface-visibility: hidden;
                        z-index: 100;

                        + .rt-tbody {
                            margin-top: 57px;
                        }
                    }
                }
            }
        }

        &--fixed-header-cart {
            > .rt-table {
                > .rt-thead {
                    &.-header {
                        position: fixed;
                        top: 83px !important;
                        backface-visibility: hidden;
                        z-index: 100;

                        + .rt-tbody {
                            margin-top: 57px;
                        }
                    }
                }
            }
        }

        &__col {
            display: flex;
            justify-content: center;
            flex-direction: column;

            &--fix-width {
                text-align: center;
            }
        }

        &__supplier,
        &__product-name {
            text-transform: uppercase;
        }

        &__on-order-list {
            img {
                margin: 0 auto;
            }
        }
    }

    &__rt.responsive-table {
        .rt-table {
            @media (max-width: 1024px) {
                .rt-tr div.rt-th,
                .rt-tr div.rt-td {
                    display: none;
                }

                .rt-tr div.rt-th:nth-child(-n + 6) {
                    display: block;
                }

                .rt-tr div.rt-td:nth-child(-n + 6) {
                    display: block;
                }

                .quantity,
                .popover-badge__wrapper {
                    display: inline-block;
                }

                .flex-row-reverse {
                    flex-direction: initial !important;
                    margin: 10px;
                }

                .popover-badge__wrapper {
                    margin-left: 6px;
                }

                .child div span,
                .child div span p {
                    display: inline-block;
                    margin-bottom: 5px;
                }
            }

            @media (max-width: 768px) {
                .rt-tr div.rt-th,
                .rt-tr div.rt-td {
                    display: none !important;
                }

                .rt-tr div.rt-th:nth-child(-n + 4) {
                    display: block !important;
                }

                .rt-tr div.rt-td:nth-child(-n + 4) {
                    display: block !important;
                }

                .quantity,
                .popover-badge__wrapper {
                    display: inline-block;
                }

                .flex-row-reverse {
                    flex-direction: initial !important;
                    margin: 10px;
                }

                .popover-badge__wrapper {
                    margin-left: 6px;
                }

                .child div span,
                .child div span p {
                    display: inline-block;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .fair-subcomponent {
        //display: flex;
        flex-wrap: nowrap;
        text-align: center;
        color: #505050;

        &.even {
            background: transparent;
        }

        &.odd {
            background-color: #f4f4f4;
        }

        > div {
            clear: both;
            width: 100%;

            .fair-subcomponent-week {
                width: 12%;
                padding-bottom: 5px;
                padding-top: 5px;
                float: left;
                margin-left: 2px;

                span {
                    margin-right: 3px;
                }

                input {
                    max-width: 80px;
                    border: 1px solid #505050;
                    border-radius: 3px;
                    text-align: center;
                }
            }
        }

        .fair-subcomponent-gadget {
            text-align: left;
            padding-left: 10px;
            padding-right: 10px;
            font-style: italic;
        }
    }

    &--loading {
        .rt-td.table__rt__col {
            position: relative;
            font-size: 0 !important;
            padding-top: 10px !important;
            padding-bottom: 10px !important;

            &:before {
                content: '';
                width: 80%;
                height: 20px;
                text-align: center;
                position: absolute;
                left: 50%;
                z-index: 1;
                transform: translate(-50%);
                animation-duration: 1.2s;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
                animation-name: placeHolderShimmer;
                animation-timing-function: linear;
                background: #f6f7f8;
                background: linear-gradient(
                    to right,
                    #e2e2e2 8%,
                    #d1d1d1 18%,
                    #e2e2e2 33%
                );
                background-size: 800px 20px;
            }
        }

        .popover-badge__badge {
            height: 0;
        }

        .rt-noData {
            display: none;
        }
    }
}

.cart-page .table {
    &__rt.responsive-table {
        .rt-table {
            @media (min-width: 1200px) and (max-width: 1310px) {
                .rt-tr div.rt-th,
                .rt-tr div.rt-td {
                    display: none;
                }

                .rt-tr div.rt-th:nth-child(-n + 9) {
                    display: block;
                }

                .rt-tr div.rt-td:nth-child(-n + 9) {
                    display: block;
                }

                .quantity,
                .popover-badge__wrapper {
                    display: inline-block;
                }

                .flex-row-reverse {
                    flex-direction: initial !important;
                    margin: 10px;
                }

                .popover-badge__wrapper {
                    margin-left: 6px;
                }

                .child div span,
                .child div span p {
                    display: inline-block;
                    margin-bottom: 5px;
                }
            }

            @media (min-width: 1100px) and (max-width: 1200px) {
                .rt-tr div.rt-th,
                .rt-tr div.rt-td {
                    display: none;
                }

                .rt-tr div.rt-th:nth-child(-n + 7) {
                    display: block;
                }

                .rt-tr div.rt-td:nth-child(-n + 7) {
                    display: block;
                }

                .quantity,
                .popover-badge__wrapper {
                    display: inline-block;
                }

                .flex-row-reverse {
                    flex-direction: initial !important;
                    margin: 10px;
                }

                .popover-badge__wrapper {
                    margin-left: 6px;
                }

                .child div span,
                .child div span p {
                    display: inline-block;
                    margin-bottom: 5px;
                }
            }

            @media (min-width: 820px) and (max-width: 1100px) {
                .rt-tr div.rt-th,
                .rt-tr div.rt-td {
                    display: none;
                }

                .rt-tr div.rt-th:nth-child(-n + 6) {
                    display: block;
                }

                .rt-tr div.rt-td:nth-child(-n + 6) {
                    display: block;
                }

                .quantity,
                .popover-badge__wrapper {
                    display: inline-block;
                }

                .flex-row-reverse {
                    flex-direction: initial !important;
                    margin: 10px;
                }

                .popover-badge__wrapper {
                    margin-left: 6px;
                }

                .child div span,
                .child div span p {
                    display: inline-block;
                    margin-bottom: 5px;
                }
            }

            @media (max-width: 820px) {
                .rt-tr div.rt-th,
                .rt-tr div.rt-td {
                    display: none !important;
                }

                .rt-tr div.rt-th:nth-child(-n + 5) {
                    display: block !important;
                }

                .rt-tr div.rt-td:nth-child(-n + 5) {
                    display: block !important;
                }

                .quantity,
                .popover-badge__wrapper {
                    display: inline-block;
                }

                .flex-row-reverse {
                    flex-direction: initial !important;
                    margin: 10px;
                }

                .popover-badge__wrapper {
                    margin-left: 6px;
                }

                .child div span,
                .child div span p {
                    display: inline-block;
                    margin-bottom: 5px;
                }
            }
        }
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.dropdown .product-switch-item,
.dropdown .product-switch-item .product-type-filter {
    background-color: #303e3d;
    color: #fff;
    border: none;
    padding: 0;
}

.dropdown .product-switch-item div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 5px 5px 5px;
}

.dropdown .product-switch-item div:last-child {
    margin: 0 5px 0 5px;
}

.dropdown
    .product-switch-item
    .product-type-filter.product-type-filter--active {
    font-weight: bold;
}

.table__header .table__header__actions-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;
}

.btn-filter {
    cursor: pointer;
    color: #505050;
    background-color: #fff;
    border: 0;
    text-decoration: underline;
}

.btn-filter:hover {
    text-decoration: none;
}

.ReactTable .rt-thead.-filters .filters-title {
    padding-top: 0;
}

.filters-lnk {
    position: absolute;
    right: 10px;
    top: 12px;
    width: 110px;
    z-index: 99;
    cursor: pointer;
    text-decoration: underline;
}

.filters-lnk:hover {
    text-decoration: none;
}

.table__header__actions-col .card-title.card-popup__body__title,
.card-popup__body__list .form-check .form-check-input[type='checkbox'] + label {
    color: #505050;
    text-transform: none;
}

.hidden-preferences {
    display: none;
}

.product__navbar__icon {
    width: 25px;
    height: 25px;
    margin-left: 8px;
    filter: brightness(0) invert(1);
}

.dropdown-item.products-dropdown-menu:hover .product-type-filter {
    background-color: #4285f4;
}

.dropdown-item.products-dropdown-menu .product-type-filter__label {
    font-size: 15px;
    text-transform: uppercase;
}

.dropdown-item.products-dropdown-menu .product-type-filter__label img {
    margin-right: 14px;
}

.dropdown
    .product-switch-item
    .fair-button-dropdown-menu
    .table__header__actions-col {
    margin-left: 30px;
}

.main-search-wrapper {
    display: flex;
    justify-content: center;
}

.search-fixed {
    position: fixed;
    top: 84px;
    z-index: 200;
}

.promotions .search-fixed {
    top: 42px;
}

.search-fixed-additional .search-elements-wrapper {
    width: 100vw;
    background: #fff;
    height: 145px;
    padding-top: 40px;
}

.promotions .search-fixed-additional .search-elements-wrapper {
    height: 186px;
}

.table .rt-table {
    overflow-x: hidden;
    overflow-y: hidden;
}

@media (max-width: 1200px) {
    .delivery .table .rt-table {
        overflow-x: auto;
    }
}

.table-preferences {
    position: relative;
}

.search_filter_close {
    position: absolute;
    right: 10px;
    top: 70px;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 400;
}

.preferences-lnk {
    width: 110px;
    z-index: 99;
    cursor: pointer;
    text-decoration: underline;
}

.preferences-lnk:hover {
    text-decoration: none;
}

.filter-btn {
    margin-left: 10px;
}

.cart-campaign-title {
    margin-top: 20px;
    margin-bottom: 10px;
    color: rgb(215, 0, 0);
    font-weight: normal;
    text-align: right;
}

.ReactTable.table__rt.table__rt--fixed-header.without-top-filters
    .rt-table
    .rt-thead.-header {
    top: 82px !important;
}

.alignHeader {
    align-items: center;
}

.productTypeHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    min-height: 80px;
}

.exhibition-wrapper {
    @media (max-width: 1200px) {
        margin-left: 0 !important;
    }

    @media (max-width: 900px) {
        margin-top: 1.4em !important;
    }
}

.table__header__actions-col--right .download-lbl {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;

    margin-right: 20px !important;
    padding-top: 4px;
    vertical-align: middle;

    @media (max-width: 1200px) {
        margin-left: 0.25rem !important;
    }
}

.table__header__actions-col--right .download-btn {
    display: inline-block;

    button {
        font-size: 12px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 !important;
    }
}

.table {
    &__rt {
        &__product-photo img {
            height: 80px;
            object-fit: scale-down;
        }
    }
}

@media (max-width: 1024px) {
    .modal-dialog {
        max-width: 756px;
    }
}

.flex-row-reverse.orders-histories {
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
}
