.date-order {
  margin-left: 15px;
  &__date {
    margin-right: 50px;
  }

  &__discount {
    margin-right: 35px;
    min-width: 30px;
    display: inline-block;
    text-align: right;
  }

  .quantity {
    display: inline-block;
    margin-right: 41px;
  }
}