.promotions {
    .search {
        margin-top: 42px;
    }
    .quantity {
        margin-bottom: 7px;
        display: flex;
        align-items: baseline;

        &__input {
            align-self: center;
            position: relative;
            bottom: 4px;
            height: 32px;
        }
    }

    .popover-badge {
        margin-bottom: 10px;
    }

    .ReactTable {
        .rt-thead {
            .rt-tr {
                .rt-th {
                    &.table__rt {
                        @media (max-width: 1200px) {
                            &__product-name {
                                max-width: 200px !important;
                            }

                            &__supplier {
                                max-width: 100px;
                            }

                            &__quantity {
                                max-width: 120px !important;
                            }

                            &__available_from,
                            &__available_to {
                                max-width: 80px !important;
                            }
                        }
                    }
                }
            }
        }
        .rt-tbody {
            .rt-tr {
                .rt-td {
                    justify-content: flex-start;

                    @media (max-width: 1200px) {
                        &.table__rt {
                            &__product-name {
                                max-width: 200px !important;
                            }

                            &__supplier {
                                max-width: 100px;
                                white-space: normal;
                            }

                            &__quantity {
                                max-width: 120px !important;
                            }

                            &__available_from,
                            &__available_to {
                                max-width: 80px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.promotions-wrapper {
    @media (max-width: 768px) {
        margin: 10px 0 0 6px;
    }
}
