// import fonts src/assets/stylesheets/base/_fonts.scss
// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

.container {
    width: 100%;
    padding: 0 15px;
}

.categoryMenu {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.categoryItem {
    .categoryItemList {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.categoryTitle {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.subcategories {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 254px);
    gap: 34px;
    list-style: none;
    padding: 0;
}

.subcategoryButton {
    background: linear-gradient(180deg, #f08200 0%, #eb5087 100%);
    border: none;
    cursor: pointer;
    color: white;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 58px;
    font-family: 'General Sans', sans-serif;
    font-weight: 600;

    &:hover {
        background-color: darken(#e7672b, 10%);
    }
}

.breadcrumbs {
    display: block;
    align-items: center;
    font-size: 14px;
    margin-bottom: 18px;
}

.breadcrumbItem {
    color: #000;
    margin: 0 5px;
    font-size: 22px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;

    span,
    &.span {
        &.first {
            font-size: 22px;
            font-weight: bold;
        }

        &.underline {
            text-decoration: underline;
        }
    }

    .name {
        font-weight: 500;
        line-height: 29px;
    }

    .name:hover {
        text-decoration: underline;
        color: #333;
    }
}

.breadcrumbSeparator {
    color: #000;
}
