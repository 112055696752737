.download-assortment {
  background: white;
  border-radius: 2.25px;
  padding: 12px 20px;
  color: $font-main-grey;
  font-weight: normal;
  text-align: center;
  border: 1px solid #E4E4E4;
  display: flex;
  cursor: pointer;

  &__label {
    font-size: 15px;
    margin-bottom: 0;
    display: inline-block;

    &__icon {
      width: 24px;
      margin-left: 12px;
      display: inline-block;
      height: 16px;

      &:before {
        content: '';
        background-image: image('download-arrow-grey.svg');
        width: 24px;
        height: 24px;
        display: block;
        background-size: contain;
      }
    }
  }
}