.title-header {
    margin-bottom: 70px;

    &__body {
        padding-top: 45px;
        padding-bottom: 45px;
        background-color: $table-datepicker-header-bg-color;
        text-align: center;
    }

    &__title {
        color: #000;
        margin: 0;
        font-weight: 600;
        font-size: 35px;
        text-transform: uppercase;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 17px;
    }
}
