// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');

@font-face {
    font-family: 'nimbus_sans_lregular';
    src: url('../fonts/NimbusSanL-Reg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Soft Bold';
    src: url('../fonts/Proxima-Nova-Soft-W03-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cervo Medium';
    src: url('../fonts/Cervo-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Anton';
    src: url('../fonts/Anton-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'General Sans';
    src: url('../fonts/GeneralSans-Variable.ttf') format('truetype');
    font-weight: 300 400 500 600 700;
    font-style: normal;
}
