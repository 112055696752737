.product-details {
    font-size: 15px;
    max-width: 756px;

    @media (max-width: 1180px) {
        margin-top: 5.2rem;
        margin-right: 0;
    }

    &__specification-list {
        border-right: 1px solid #ececec;

        .column-list {
            display: flex;
            flex-direction: column;
        }
    }

    &__left-side {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__category {
        padding: 0 !important;

        div {
            span {
                font-size: 12px !important;
                color: #707070;
                font-weight: 500 !important;
            }
        }

        .body-content-top-products-separator {
            display: none;
        }
    }

    &__backdrop {
        background-color: rgba(255, 255, 255, 0.95);
    }

    .tagsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-left: 15px;
        margin-top: 50px;

        .tag {
            background-color: #e8e8e8;
            color: white;
            padding: 4px 16px;
            border-radius: 5px;
            border: 1px solid #262626;

            &__text {
                font-size: 14px;
                color: #262626;
            }
        }
    }

    .modal-close {
        &:after {
            background-image: image('close-grey.svg');
            height: 14px;
            width: 14px;
            right: 10px;
            top: -23px;
            left: auto;
        }
    }

    .modal-body {
        padding: 35px 38px 54px 38px;

        &--loading {
            text-align: center;
        }
    }

    &__title {
        font-size: 25px;
        font-weight: 600;
        color: #707070;
    }

    &__order {
        margin-bottom: 27px;

        .quantity {
            max-width: 160px;
            border: 1px solid #9a9a9a;
            align-content: center;
            justify-content: center;

            &__icon,
            &__input:not(.quantity__input__active) {
                cursor: pointer;
            }
        }

        &__on-order-list {
            img {
                cursor: pointer;
                position: relative;
                top: -2px;
            }

            button {
                background-color: transparent;
                border: none;
                margin-left: 0px;
                font-size: 12px;
                color: #707070;
                padding: 0;
                cursor: pointer;
                text-transform: uppercase;
                text-decoration: underline;
            }
        }

        &__price {
            font-size: 25px;
            font-weight: normal;
        }
    }

    &__promotion-campaign {
        margin-bottom: 37px;

        &__list {
            display: flex;
            justify-content: space-between;
            gap: 15px;
            margin-left: 0;
            margin-right: 0;

            div {
                flex: 1;
            }
        }
    }

    &__promotion,
    &__campaign {
        color: white;
        padding: 15px;
        font-size: 16px;
        line-height: 1;
        margin: 1px;
        border-radius: 4px;
        font-weight: 600;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span {
            line-height: 1;
            font-size: 16px;
        }

        div {
            height: min-content;
        }
    }

    &__promotion {
        margin: 1px;

        &--1 {
            background-color: #ffde00;
        }

        &--0 {
            background-color: #d70000;
        }

        &--is-future {
            background-color: gray;
        }
    }

    &__images {
        margin-top: 20px;
        margin-bottom: 68px;

        &__wrapper {
            &__image {
                max-height: 400px;
                display: block;
                margin: 0 auto 14px auto;
            }
        }
    }

    &__section {
        &__title {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 23px;
            color: #707070;
        }
    }

    &__specifications {
        margin-bottom: 40px;
        border-top: 1px solid #ececec;
        border-bottom: 1px solid #ececec;
        padding: 14px 0;

        &__specification {
            margin-bottom: 4px;
            display: inline-block;
            font-size: 14px;
            color: #707070;

            strong {
                font-weight: 600;
                color: #707070;
            }
        }
    }

    &__description {
        margin-bottom: 50px;

        &__text {
            font-size: 14px;
            color: #707070;
            font-weight: 400;
        }
    }

    &__allergens {
        &__legend {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;

            &__icon {
                margin-right: 5px;

                &.allergens__not-contain {
                    border-radius: 50%;
                    width: 12px;
                    height: 12px;
                    background-color: grey;
                }
            }

            span {
                margin-left: 15px;

                &:first-of-type {
                    margin-left: 0;
                }

                &:first-of-type {
                    &:before,
                    &:after {
                        display: none;
                    }
                }

                &:last-of-type {
                    margin-right: 11px;
                }
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: left;

            &__allergen {
                margin: 0 21px 0 0;
                opacity: 1;
                width: 60px;

                &--contain {
                    path {
                        fill: #008000 !important;
                    }

                    ellipse {
                        stroke: #008000 !important;
                    }

                    circle {
                        stroke: #008000 !important;
                    }

                    polyline {
                        stroke: #008000 !important;
                    }

                    rect {
                        fill: #008000 !important;
                    }

                    text {
                        fill: #008000 !important;
                    }

                    //filter: invert(24%) sepia(93%) saturate(2124%) hue-rotate(96deg) brightness(95%) contrast(102%);
                }

                &--not-contain {
                    path {
                        fill: grey !important;
                    }

                    ellipse {
                        stroke: grey !important;
                    }

                    circle {
                        stroke: grey !important;
                    }

                    polyline {
                        stroke: grey !important;
                    }

                    rect {
                        fill: grey !important;
                    }

                    text {
                        fill: grey !important;
                    }
                }

                &--can-contain {
                    path {
                        fill: red !important;
                    }

                    ellipse {
                        stroke: red !important;
                    }

                    circle {
                        stroke: red !important;
                    }

                    polyline {
                        stroke: red !important;
                    }

                    rect {
                        fill: red !important;
                    }

                    text {
                        fill: red !important;
                    }

                    //filter: invert(24%) sepia(83%) saturate(7421%) hue-rotate(357deg) brightness(110%) contrast(121%);
                }

                &--no-info {
                    display: none;
                }
            }
        }
    }

    &__file-box {
        max-width: 255px;
        display: inline-flex;
        margin-right: 31px;
        margin-bottom: 30px;
        border: 1px solid $recipes-item-box-border-color;
        text-align: center;
        background-color: #fff;
        box-shadow: none;
        cursor: pointer;
        position: relative;
        border-radius: 0;

        &:hover {
            .product-details__file-box__image-wrapper {
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background: rgba(0, 0, 0, 0.8)
                        image('download-arrow-white.svg') no-repeat center;
                }
            }
        }

        &__link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;

            &.inline {
                padding: 0 15px;

                span {
                    text-transform: capitalize;
                    text-decoration: underline;
                    font-size: 16px;
                    color: #707070;
                    font-weight: 500;
                }

                button {
                    margin: 0;
                    background-color: #ff0909;
                    color: #fff;
                    border-radius: 4px;
                    padding: 4px 12px;
                    margin-right: 10px;
                }
            }
        }

        &__image-wrapper {
            height: 146px;
            width: 254px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__title {
            font-weight: 300;
            font-size: 14px;
        }

        .card-body {
            border-top: 1px solid $recipes-item-box-border-color;
            padding-bottom: 0;
            padding-top: 12px;
        }
    }

    &__allergens-list {
        margin-bottom: 40px;
    }

    &__wrapper-header {
        // margin: -35px -38px 0;
        // padding: 35px 25px 0;
    }

    &__article_nr {
        font-size: 10px;
        margin-bottom: 0px;

        p {
            margin-bottom: 0px;
            font-size: 10px;

            strong {
                font-size: 10px;
                font-weight: 400;
            }
        }
    }

    .product-novelty__item-box__price {
        font-size: 22px;
        padding: 0;

        @media (max-width: 1200px) {
            max-width: 100%;
        }
    }

    .quantity {
        text-align: end;
    }
}
