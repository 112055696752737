.item-box {
  padding: 11px;
  max-width: 255px;
  display: inline-flex;
  margin-right: 11px;
  margin-left: 11px;
  margin-bottom: 30px;
  box-shadow: none;
  border: 1px solid $recipes-item-box-border-color;
  text-align: center;
  background-color: #FFF;
  transition: box-shadow .1s;
  cursor: pointer;
  position: relative;
  border-radius: 0;

  .rgba-white-slight, .rgba-white-slight:after {
    background-color: transparent;;
  }

  &:hover {
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.50);

    .item-box {
      &__image-wrapper {
        .view:first-child {
          display: none;
        }
      }

      &__image {
        &--hoverable {
          display: none;
        }
        &--hover {
          display: block !important;
        }
      }
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &__image-wrapper {
    background-color: #EDEDED;
    max-width: 231px;
    height: 177px;
    display: flex;
  }

  &__image {
    object-fit: cover;
    background-color: #EDEDED;
    max-width: 231px;

    &--hover {
      display: none !important;
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 8px;
  }

  &__text {
    font-weight: 300;
    font-size: 14px;
  }

  .card-body {
    padding-bottom: 0;
  }
}