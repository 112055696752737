.master-table-header {
    padding-top: 44px;
    padding-bottom: 28px;
    padding-left: 140px;
    padding-right: 140px;
    background-color: $table-datepicker-header-bg-color;
    margin-bottom: 36px;

    &__label {
        font-size: 20px;
        margin-right: 10px;
        position: relative;
        top: 8px;
        margin-left: 14px;
        font-weight: 400;
        color: #000;
    }

    &__datepicker-wrapper {
        text-align: left;
        padding-top: 20px;

        @media (max-width: 1180px) {
            margin-bottom: 14px;
        }

        padding-left: 00px;
    }

    &__datepicker {
        display: inline-flex;

        .MuiInput-underline-11 {
            &:before,
            &:after {
                display: none;
            }
        }

        input {
            font-size: 20px;
            font-weight: bold;
            font-family: 'General Sans', sans-serif;
            padding-bottom: 0;
            text-transform: uppercase;
            text-align: center;
            min-width: 350px;
        }
    }

    .callendar.icon {
        //margin-left: -10px;
        cursor: pointer;
        align-self: flex-start;
        margin-top: 6px;
        width: 20px;
    }

    @media (max-width: 1180px) {
        .container {
            max-width: 100%;
        }
    }

    .container-fluid.row {
        padding-right: 0;
    }
}

.overflow-hidden {
    overflow-y: hidden;
}
