.preferences {
    &__icon {
        cursor: pointer;
    }
    text-align-last: end;
    padding-right: 32px;
}

.preferences .card {
    text-align-last: left;
}

.preferences-photo {
    cursor: pointer;
    text-decoration: underline;
}

.preferences-photo:hover {
    text-decoration: none;
}

.preferences-photo-wrapper .form-check.my-3 {
    margin-top: initial !important;
    padding-left: initial !important;
}
