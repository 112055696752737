.filters {
    text-align: center;

    &__selected {
        display: flex;
        flex-direction: column;

        & > div.chip {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: transparent;
            margin-top: 0;
            padding: 0;
            text-transform: lowercase;
            color: $font-main-grey;

            // important is used to override the chip styles which also have !important
            &:active {
                box-shadow: none !important;
            }

            .filters__remove-filter::after {
                top: 0;
                left: 0;
            }
        }
    }

    .chip {
        background-color: $filters-chip-bg-color;
        margin: 22px 1em 0 0;
        text-transform: uppercase;
        cursor: default;
        height: auto;
    }

    &__icon {
        cursor: pointer;
        width: 24px;
    }

    &__opener {
        display: inline-flex;
        align-items: center;
        margin-top: 30px;
        cursor: pointer;

        img {
            margin-right: 10px;
        }

        span {
            font-size: 14px;
            text-decoration: underline;
            font-weight: normal;
        }
    }

    &__remove-filter {
        position: relative;
        padding-right: 10px;
        cursor: pointer;
        bottom: 1px;

        &.chip_remove {
            border: none;
            background-color: transparent;
            width: 10px;
            height: 10px;
            padding-right: 0;
        }

        &:after {
            content: '';
            background-image: image('close-grey.svg');
            height: 7px;
            width: 7px;
            background-size: contain;
            position: absolute;
            z-index: 10;
            bottom: 3px;
            margin-left: 6px;
        }
    }

    &__label {
        font-size: 14px;
    }

    .modal-backdrop {
        opacity: 0 !important;
        background-color: #ffffff;
    }

    &__modal {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;

        .modal-close {
            &:after {
                width: 15px;
                height: 15px;
                left: -30px;
                bottom: -30px;
            }
        }

        .modal-content {
            height: auto;
            min-height: 100%;
            border-radius: 0;
            padding: 0 165px;
            box-shadow: none;
            display: flex;
            justify-content: center;

            @media (max-width: 1300px) {
                padding: 0 0;
            }
        }

        .form-check {
            margin-top: 4px !important;
            margin-bottom: 8px !important;
            padding-right: 1.25rem;
            padding-left: 0;
            text-align: left;
        }

        &__box {
            padding: 31px 24px;
            margin-bottom: 15px;
            //background-color: $filters-box-bg-color;

            &--with-border {
                &:first-of-type {
                    border-right: 8px solid white;
                }

                &:nth-of-type(2) {
                    border-left: 8px solid white;
                }

                border-left: 15px solid white;
                border-right: 15px solid white;
            }
        }

        &__title {
            text-align: center;
            margin-top: 6px;
            margin-bottom: 30px;
            color: $filters-header-color;
            font-size: 30px;
            font-weight: normal;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                margin-right: 10px;
            }
        }

        &__section-title {
            font-weight: normal;
            font-size: 14px;
            margin-bottom: 22px;
            color: $filters-header-color;
        }

        &__allergens {
            &--non-mandatory {
                &__group {
                    display: inline-flex;
                    position: relative;
                    top: -5px;

                    .form-check-label {
                        margin-right: 0 !important;
                        text-transform: uppercase;
                        font-size: 12px;
                    }
                }
            }
        }

        &__allergens-list {
            &__list {
                display: flex;
                flex-flow: row wrap;
                align-items: flex-start;
                align-content: flex-start;
                position: relative;
                left: -15px;

                .form-check {
                    flex: 0 1 75px;
                    margin-bottom: 0px !important;
                    margin-top: 0 !important;
                    padding-left: 0 !important;
                    padding-right: 0;
                    cursor: pointer;
                    text-align: center;

                    &.filters__modal__filter__label--selected {
                        &:after {
                            content: '';
                            background: image('check-green-css.png') no-repeat !important;
                            width: 20px;
                            height: 16px;
                            position: absolute;
                            right: 11px;
                        }
                    }
                }

                label {
                    margin-right: 0 !important;
                    padding-left: 0 !important;
                    height: auto !important;

                    &:before,
                    &:after {
                        display: none;
                    }
                }
            }
        }

        &__section {
            &__header {
                padding-bottom: 40px;
            }

            &__title {
                font-weight: normal;
                margin-bottom: 0;
                color: $filters-header-color;
                font-size: 24px;
            }

            &__subtext {
                font-size: 12px;
                display: block;
            }

            &__remove {
                opacity: 0.6;
                transition: opacity 0.2s;
                cursor: pointer;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .filters__modal__allergens--non-mandatory__group {
        display: block !important;
    }

    .d-inline-flex {
        display: block !important;
    }
}

.search__input.common-search-filter {
    background-image: image('search-grey.svg');
    background-repeat: no-repeat;
    background-position: center right 12px;
    background-size: 30px;
    width: 100%;
}

.select-wrapper.md-form {
    position: relative;

    .chevron {
        position: absolute;
        right: 6px;
        top: 10px;
    }

    span.caret {
        display: none;
    }

    input::after {
        background-color: pink;
    }
}
