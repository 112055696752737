.redirect-to-fair-button {
    background: red;
    border-radius: 2.25px;
    padding: 12px 20px;
    color: white;
    font-weight: normal;
    text-align: center;
    border: 1px solid #e4e4e4;
    display: flex;
    cursor: pointer;

    &__label {
        font-size: 15px;
        margin-bottom: 0;
        display: inline-block;
    }
}
