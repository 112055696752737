.card.product-novelty__item-box {
    overflow: visible;
}

.with-refund {
    margin-top: 28px;
}

.top-products {
    .toplist-banner {
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
            0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }

    .product-novelty {
        &__item-box {
            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
                0 4px 15px 0 rgba(0, 0, 0, 0.15);

            &__body-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 2px;
            }

            &__title {
                margin-bottom: 0;
            }

            .card-body {
                // background: $product-box-background;
            }

            &__separator {
                border-top: 1px solid #f1f1f1;
                width: 94%;
                margin: 12px auto 0;
            }

            &__weeks {
                height: 158px;
            }
        }
    }
}

.filters__remove-filter:after {
    z-index: -1;
}

.product-novelty {
    max-width: 1200px;
    margin: 0 auto 80px;
    overflow-x: hidden;

    &__item-box {
        box-shadow: none;
        border: 1px solid #e4e4e4;
        border-radius: 0;
        max-width: 260px;
        margin-right: 10px;
        margin-bottom: 10px;
        display: inline-flex;
        position: relative;
        width: 256px;

        @media (max-width: 1200px) {
            max-width: 240px;
        }

        .card-body {
            position: relative;
            padding: 1.25rem 0 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &__image-wrapper {
            text-align: center;
            cursor: pointer;
            height: 160px;

            @media (max-width: 1200px) {
                width: 238px;
            }

            img {
                /*width: 100%;*/
                max-height: 158px;
                object-fit: cover;

                @media (max-width: 1200px) {
                    height: 128px;
                }
            }
        }

        &__image-placeholder {
            background-color: #ededed;
            width: 187px;
            height: 177px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
        }

        &__supplier-title {
            font-size: 12px;
            text-align: center;
            font-family: 'General Sans', sans-serif !important;
        }

        &__no-refund-title {
            font-size: 12px;
            text-align: center;
            color: green;
            margin-bottom: 10px;
        }

        &__no-refund-small-title {
            font-size: 9px;
            font-weight: bold;
            text-align: center;
            color: green;
            margin-bottom: 10px;
        }

        &__title {
            font-size: 16px;
            text-align: center;
            min-height: 60px;

            cursor: pointer;
            font-weight: 600 !important;
            font-family: 'General Sans', sans-serif !important;

            @media (max-width: 1200px) {
                min-height: 80px;
            }
        }

        &__wrapper-promotions {
            // hide block
            /*display: none;*/
            /*margin-bottom: 12px;
            min-height: 88px;*/
            cursor: pointer;
        }

        &__promotion-title {
            font-size: 16px;
            text-align: center;
        }

        &__promotion-date {
            font-size: 10px;
            text-align: center;
            /*margin-top: 25px;*/
            margin-top: 4px;
        }

        &__separator {
            border-top: 1px solid #f1f1f1;
            width: 94%;
            margin: 12px auto 0;
        }

        &__article-nr-wraper {
            /*padding: 0 10px 10px 10px;*/
            padding: 0 10px 0 10px;
            text-align: center;
        }

        &__article-nr {
            font-size: 10px;
            color: #707070;
        }

        &__price-wraper {
            padding: 0 15px 10px 15px;
        }

        &__price {
            font-weight: 600;
            margin: auto;
            font-size: 18px;
            padding-left: 11px;

            &.card_price {
                font-size: 18px;
            }

            @media (max-width: 1200px) {
                font-size: 13px;
                max-width: 100px;
            }
        }

        &__quantity {
            min-height: 40px;
            margin: auto;
            padding: 0 15px 0 0;
        }

        &__quantity {
            .quantity .not_available {
                margin-top: 8px;
                display: block;
            }
        }

        &__promotions {
            position: absolute;
            display: inline-flex;
            top: -30px;
            padding-left: 9px;
        }

        &__promotion {
            position: relative;
            display: inline-flex;
            text-align: center;
            margin-top: 22px;
            margin-right: 4px;
            bottom: 10px;
            left: 0;
            right: 0;

            &__text {
                font-size: 14px;
            }

            &__badge {
                display: inline-block;
                margin-left: 11px;
            }

            &__available-date {
                font-size: 12px;
                margin-bottom: 0;
                margin-top: 19px;
            }

            .popover-badge__badge {
                width: 30px;
                height: 30px;
                font-size: 16px;
                font-weight: 700;
                box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
                    0 4px 15px 0 rgba(0, 0, 0, 0.15);
            }

            .tooltip.show {
                min-width: 240px;
            }
        }

        &__weeks {
            height: 148px;
            background: #fff;
            padding-top: 20px;
        }

        .favourite-icon {
            position: absolute;
            top: 14px;
            right: 21px;
        }

        .new-text {
            position: absolute;
            top: 10px;
            /* Adjust the top position as needed */
            left: 10px;
            /* Adjust the right position as needed */
            background-color: #ff0000;
            /* Adjust the background color as needed */
            color: #ffffff;
            /* Adjust the text color as needed */
            padding: 5px;
            /* Adjust the padding as needed */
            font-weight: bold;
            border-radius: 5px;
            /*transform: rotate(-45deg);*/
        }

        &__points-wrapper {
            float: right;
            margin-left: 2px;
        }
    }
}

.product-novelty__item-box:hover {
    .product-novelty__item-box__image-wrapper {
        display: none;
    }

    .product-novelty__item-box__weeks {
        display: block !important;
    }

    .alignRight {
        justify-content: center;
    }

    .product-novelty__item-box__weeks .flex-row-reverse {
        flex-direction: column !important;
    }

    .product-novelty__item-box__promotions {
        top: 200px;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 4px; // needs to be 6, but this elem has 2 px margin right
        padding: 0;
    }

    .quantity__count {
        display: none;
    }

    .quantity .hidden {
        visibility: visible;
    }
}

.top-products {
    .product-novelty__item-box:hover {
        .product-novelty__item-box__promotions {
            top: 300px;

            //override because of old block is hidden
            top: 190px;
        }
    }
}

.product-novelty__item-box {
    position: relative;
}

.product-novelty__item-box__new-product-mark {
    position: absolute;
    left: 0;
    top: 0;
}

.product-novelty__item-box:hover .product-novelty__item-box__new-product-mark {
    visibility: hidden;
}

.box-products {
    display: grid;
    grid-template-columns: repeat(auto-fit, 300px);
    gap: 34px;
    width: 100%;

    @media (max-width: 2277px) {
        grid-template-columns: repeat(auto-fit, 260px);
    }

    @media (max-width: 1200px) {
        grid-template-columns: repeat(auto-fit, 238px);
    }
}

.container-fluid {
    padding: 0 140px;

    @media (max-width: 1300px) {
        padding: 0 60px;
    }
}

.infinite-scroll-component.box-products {
    overflow-x: hidden !important;
}

.box-products {
    @media (max-width: 850px) {
        margin-left: 15%;
    }

    @media (max-width: 750px) {
        margin-left: 10%;
    }

    @media (max-width: 650px) {
        margin-left: 0;
    }
}
