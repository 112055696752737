/**
 * Basic typography style for copy text
 */
body {
    color: $font-main-grey;
    font-family: 'General Sans', sans-serif;
}

input,
button,
select,
textarea {
    font-family: 'General Sans', sans-serif;
}
