.dropdown-content li span {
    color: #000;
}

.overflow {
    overflow: hidden;
}

.search {
    max-width: 600px;
    border: 1px solid #e4e4e4;
    overflow: visible;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    height: 61px;
    position: relative;

    @media (max-width: 993px) {
        max-width: 65%;
        margin-bottom: 25px;
    }

    &__icon {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }

    &--with-select {
        max-width: 730px;

        .search__input {
            width: 390px;
        }
    }

    &__input {
        padding: 14px 15px;
        width: 480px;
        background: #ffffff;
        border: none;
        font-size: 16px;

        &:focus,
        &:active {
            outline: none;
        }

        /* CANNOT COMBINE THOSE SELECTORS AS THEY WONT WORK :( */
        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $search-placeholder-text-color;
            opacity: 1;
            /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $search-placeholder-text-color;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $search-placeholder-text-color;
        }
    }

    &__input-whith-filters {
        width: 148px;
    }

    &__btn {
        position: absolute;
        right: 0;
        width: 140px;
        font-size: 18px;
        padding: 17px 15px;
        margin: 0;

        &:focus,
        &:active,
        &:hover {
            box-shadow: none;
        }
    }

    .select-wrapper {
        padding: 15px 0;
        background-color: #fff;
        margin-top: 0;
        margin-bottom: 0;

        span {
            &.chevron {
                top: 1.65rem;
                z-index: 0;
                right: 15px;
            }
        }

        input {
            &.select-dropdown {
                margin-bottom: 0;
                padding: 0 27px;
                height: 100%;
                width: auto;
                border-right: 1px solid #979797;
                border-bottom: none;
            }
        }

        ul {
            &.select-dropdown {
                #input {
                    + label {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    .dropdown-content {
        &.fadeIn {
            display: block;
        }

        &.fadeElement {
            transition: 0.2s;
        }

        li {
            &.active,
            &:hover {
                background-color: $brand-color;

                span {
                    color: #000 !important;
                    color: #000 !important;
                }

                > a,
                > span {
                    color: white;
                }
            }

            &.disabled {
                > a,
                > span {
                    color: #000;
                    font-weight: normal;
                    padding-bottom: 0;
                }
            }

            &:not(.disabled) {
                padding-left: 10px;

                > a,
                > span {
                    text-transform: uppercase;

                    padding-top: 4px;
                    padding-bottom: 4px;
                }
            }

            > a,
            > span {
                color: $font-main-grey;
            }

            &.all-categories {
                padding-left: 0;

                > a,
                > span {
                    color: #000;
                    font-weight: normal;
                }

                &.active,
                &:hover {
                    > a,
                    > span,
                    > li {
                        color: #fff !important;
                    }
                }
            }
        }

        input {
            + label {
                &.active {
                    color: $font-main-grey !important;
                }

                + i {
                    display: none;
                }
            }

            &:focus {
                box-shadow: 0 1px 0 0 $font-main-grey !important;
                border-bottom: 1px solid $font-main-grey !important;

                + label {
                    color: $font-main-grey !important;
                }
            }
        }
    }
}

.dropdown-multi > .dropdown-container > .dropdown-content {
    opacity: 1;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
        0 2px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    display: block;
    overflow-y: initial;
}

.dropdown-multi {
    /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
    background-color: #fff;
    margin: 0;
    color: #000;
    font-size: 14px;
    margin-bottom: 20px;
}

.dropdown-multi.rmsc .dropdown-container {
    border: none;
    border-bottom: 1px solid #ced4da;

    &:focus-within {
        box-shadow: 0 1px 0 0 #4285f4;
    }
}

.dropdown-heading {
    text-align: left;

    &:focus-within {
    }
}

.dropdown-multi.rmsc .dropdown-heading {
    padding: 0;
}

.dropdown-multi.rmsc .dropdown-heading > .dropdown-heading-value > span {
    font-family: 'General Sans', sans-serif !important;
    color: #505050 !important;
    line-height: 2.9rem;
    font-size: 1rem;
    font-weight: 600;
}

.rmsc > .dropdown-container:focus {
    border: none;
}

.dropdown-multi.rmsc .gray {
    color: #000;
}

.rmsc .dropdown-container .panel-content {
    padding: 20px 5px 0px;
}

.rmsc .dropdown-container .panel-content .search {
    border: none;
    margin-bottom: 20px;
    border-bottom: 1px solid #ced4da;
    height: min-content;
}

.rmsc .dropdown-container .panel-content .search input:focus {
    background-color: #fff;
    font-size: 20px;
}

.rmsc .dropdown-container .panel-content .options {
    overflow-x: hidden;
}

.rmsc .dropdown-container .panel-content .options .select-item {
    font-size: 0.9rem;
    display: block;
    padding: 0.5rem;
}

.dropdown-multi.rmsc .dropdown-content .panel-content {
    box-shadow: none;
    padding: 5px;
}

.supplier-wrapper .dropdown-multi.rmsc .dropdown-content {
    position: relative;
}

.supplier-item {
    width: 100%;
    height: 100%;
}

.selectedValue {
    font-size: 12px;
    background-color: #fff;
    color: #000;
    padding: 5px 10px;
    border-radius: 50px;
    margin: 5px;
}

.main-search-wrapper .select-wrapper .select-dropdown,
.main-search-filter-wrapper .select-wrapper .select-dropdown {
    font-family: 'General Sans', sans-serif !important;
    color: #505050 !important;
    text-transform: capitalize;
    font-weight: 600;
}

.main-search-filter-wrapper {
    margin-top: 10px;

    @media (min-width: 992px) {
        max-width: 256px;
    }

    .suppliers_chips {
        display: flex;
        flex-direction: column;

        & > .suppliers_chip {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: transparent;
            margin-top: 0px;
            padding: 0px;
            color: $font-main-grey;
            margin-bottom: 0px;

            // important is used to override the chip styles which also have !important
            &:active {
                box-shadow: none !important;
            }

            .filters__remove-filter::after {
                top: 0px;
                left: 0px;
            }
        }
    }
}
img.keurslager-icon {
    margin-right: 5px;
    padding-bottom: 4px;
    width: 20px;
}
