.dropdown {
    .dropdown-menu {
        box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.5);
        font-size: 15px;
        color: $font-main-grey;
        letter-spacing: 0;
        border-radius: 0;
        border: none;
        padding: 25px 0;

        a {
            padding: 0;
            font-size: inherit;
            font-weight: 300;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
            .dropdown-item {
                color: $font-main-grey !important;
                padding: 0.5rem 25px;
                cursor: pointer;

                &:hover {
                    background-color: $brand-color !important;
                    box-shadow: none !important;
                }

                @media (max-width: 1200px) {
                    white-space: initial;
                }
            }
        }
    }
}
