.product-order-history {
  &__badge {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    box-shadow: none;
    border: none;
    background-color: #868585;
    color: #FFF;
    justify-self: center;
    align-self: center;
    padding-top: 4px;
    cursor: pointer;
  }
}