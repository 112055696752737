.password {
    position: relative;

    &__input {
        &::-ms-reveal {
            display: none;
        }
    }

    &__show {
        position: absolute;
        top: 14px;
        right: 0;
        cursor: pointer;
        color: white;
        font-size: 20px;
    }
}
