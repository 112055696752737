.language-switcher {
    span {
        font-weight: 500;
        cursor: pointer;
    }

    &--active {
        text-decoration: underline;
    }

    &--header {
        font-size: 12px;
        color: #ffffff;
        line-height: 25px;
        padding: 15px 20px 15px;

        span {
            &:first-of-type {
                margin-right: 6px;
            }
            &:last-of-type {
                margin-left: 6px;
            }
        }
    }

    @media (max-width: 1200px) {
        &--header {
            display: flex;
            align-items: center;
            padding-top: 20px !important;
        }
    }

    &--footer {
        font-size: 10px;

        &__separator {
            margin-right: 3px;
            margin-left: 3px;
        }
    }
}
