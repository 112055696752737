.dashboard {
    background: linear-gradient(180deg, $main-orange 0%, $main-pink 100%);
    height: 100%;
    text-align: center;
    min-height: 100vh;

    &__dropdowns {
        display: flex;
        gap: 40px;
        width: 100%;

        &__container {
            max-width: 980px;
            margin-right: auto;
            margin-left: auto;
            padding-right: 30px;
            padding-left: 30px;
        }

        &__customer {
            flex-grow: 2;
        }

        .account-dropdown-menu {
            background-color: #fff;
            color: #000;
            padding: 10px 12px;
            border-radius: 7px;
            flex-grow: 1;
        }
    }

    &__logo {
        margin-top: 80px;
        margin-bottom: 84px;
    }

    &__account-switch {
        text-align: left;
        border-bottom: 1px solid white;

        .nav-link {
            padding-left: 0;
            padding-right: 0;
        }

        &__label {
            color: #000;

            &__account {
                font-weight: bold;
                font-size: 14px;
            }
        }

        &.account-dropdown-menu {
            .dropdown-toggle {
                &:after {
                    display: inline-block !important;
                    content: ' ';
                    float: right;
                    border: none;
                    background: image('select-arrow-white.svg') no-repeat;
                    width: 12px;
                    height: 8px;
                    margin-top: 10px;
                    position: inherit;
                }
            }

            .dropdown-menu {
                width: 100%;
                margin-top: 10px;
            }
        }
    }

    &__notifications-container {
        flex-wrap: wrap;
        margin-top: 65px;
        transition: height 1s;
        padding-left: 30px;
        padding-right: 30px;
    }

    &__sections {
        margin-top: 80px;
    }

    &__section {
        &__wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &-button {
            font-size: 20px;
            position: relative;
            border: 2px solid #ffffff;
            border-radius: 8px;
            width: 20%;
            height: 175px;
            width: 175px;
            color: white;
            display: inline-block;
            margin-bottom: 35px;
            margin-left: 19px;
            margin-right: 19px;
            background-color: transparent !important;
            transition: all 0.3s ease;
            font-family: 'Anton', sans-serif;

            &:hover {
                border: 4px solid #ffffff;
                box-shadow: 0px 0px 10px 0px #424242;
            }

            &--disabled {
                pointer-events: none;
                background-color: rgba(255, 255, 255, 0.2) !important;
                /*filter: invert(24%) sepia(0%) saturate(1%) hue-rotate(144deg)
                    brightness(88%) contrast(84%);*/
            }

            &__icon {
                margin-top: 40px;
                margin-bottom: 6px;
                height: 45px;
            }

            &__label {
                font-size: 22px;
                display: block;
            }

            a {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 100;
            }
        }
    }

    &__fair__section {
        &__wrapper {
            justify-content: center;
        }
    }
}

@media (max-width: 1200px) {
    .dropdown-toggle:after {
        position: absolute;
        margin-left: 0px !important;
        top: 65px;
        left: 12px;
    }
}

@media (max-width: 1195px) {
    .dropdown-toggle:after {
        position: initial;
        margin-left: 6px !important;
        top: initial;
        left: initial;
    }
}

@media (max-width: 991px) {
    .dropdown-toggle:after {
        position: absolute;
        top: 60px;
        left: 6px;
    }
}

@media (max-width: 910px) {
    .dropdown-toggle:after {
        position: absolute;
        top: 60px;
        left: 24px;
    }
}

@media (max-width: 768px) {
    .dropdown-toggle:after {
        position: absolute;
        top: 65px;
        left: 24px;
    }
}

@media (max-width: 420px) {
    .dropdown-toggle:after {
        position: absolute;
        top: 65px;
        left: 8px;
    }
}

@media (max-width: 439px) {
    .dashboard__section__wrapper {
        justify-content: center;
    }
}
