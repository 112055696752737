.delete-table-row {
  position: relative;
  &:after {
    display: none;
    content: ' ';
    background-image: image('trash-grey.svg');
    height: 20px;
    width: 20px;
    position: absolute;
    right: 15px;
    top: -8px;
    cursor: pointer;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
  }
}