.page-header {
  margin-top: 55px;
  &__boxes {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin-bottom: 40px;

    @media (max-width: 1023px) {
      grid-template-columns: repeat(2, 1fr);

      .filter-box, .discount-summary-box {
        max-width: none;
      }
    }
  }

  &__search {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, 1fr);

    .search {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    .delivery-calendar__button {
      margin: 0;
      grid-column-start: 4;
      grid-column-end: 5;
    }
  }
}