.login {
    &__sign-up {
        text-align: center;

        button {
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        }
    }

    &__logo {
        margin-top: 158px;
    }

    &__title {
        margin-bottom: 32px;
        font-family: 'Anton', sans-serif;
        font-size: 30px;
        line-height: 50px;
        color: #fff;
        text-align: center;
    }

    &__input {
        margin-bottom: 14px;
        width: 100%;
        height: 60px;
        padding: 24px 20px;
        outline: none;
        font-family: 'General Sans', sans-serif;
        font-weight: 400;
        background-color: #fff;
        border: 1px solid transparent;
        border-radius: 6px;
        font-size: 16px;

        &::placeholder {
            color: $my-account-new-password-placeholder-text-color;
        }
    }

    .form-container {
        max-width: 558px;
        margin: 70px auto 222px;
        padding: 24px 63px 42px 45px;
        border: 2px solid #fff;
        border-radius: 19px;

        & > div {
            padding: 0;
        }
    }
}
