// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
    box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
    box-sizing: inherit;
}

#root {
    overflow-x: hidden;
}

.container {
    &--fixed-header {
        margin-top: 83px;
    }

    &.container-smaller {
        max-width: 980px;
    }

    &-fluid {
        &.full-width {
            width: 100vw;
            position: relative;
            margin-left: -50vw;
            left: 50%;
            padding: 0;
        }
    }
}

.body-content-top-products-separator {
    border-bottom: #000 2px solid;
    margin-bottom: 50px;
    width: 100%;
}

.body-content-top-list-products-separator {
    border-bottom: $main-pink 4px solid;
    margin-bottom: 50px;
    width: 100%;
    text-align: center;
}

.body-content-top-list-products-separator .text-content {
    display: inline-block;
    background-color: #fff;
    padding: 0 13px;
    position: relative;
    top: 20px;
    color: $main-pink;
    font-weight: bold;
    font-size: 28px;
    font-family: 'Anton', sans-serif !important;
}

.btn {
    border-radius: 0;

    &.btn-flat {
        color: auto; //Fix for color inherit where it was not working all the time
    }
}

.btn-main,
.btn-brand {
    background-color: $brand-color !important;
    box-shadow: none;

    &:not([disabled]) {
        &:not(.disabled) {
            &:active {
                background-color: $brand-color !important;
                box-shadow: none;
            }
        }

        &:not(.disabled).active {
            background-color: $brand-color !important;
            box-shadow: none;
        }
    }

    &--with-shadow {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
            0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }
}

.btn-pdf {
    font-size: 12px;
    margin: 0;
    padding: 8px 25px;
    line-height: 1;
    color: #fff !important;
    box-shadow: none;

    &:not([disabled]) {
        &:not(.disabled) {
            &:active {
                box-shadow: none;
            }
        }

        &:not(.disabled).active {
            box-shadow: none;
        }
    }
}

.btn-red {
    background-color: $btn-pdf-bg-color !important;

    &:not([disabled]) {
        &:not(.disabled) {
            &:active {
                background-color: $btn-pdf-bg-color !important;
            }
        }

        &:not(.disabled).active {
            background-color: $btn-pdf-bg-color !important;
        }
    }
}

.btn-blue {
    background-color: $btn-blue-bg-color !important;
    color: #fff;

    &:not([disabled]) {
        &:not(.disabled) {
            &:active {
                background-color: $btn-blue-bg-color !important;
            }
        }

        &:not(.disabled).active {
            background-color: $btn-blue-bg-color !important;
        }
    }
}

.btn-grey {
    font-size: 12px;
    background-color: $btn-grey-bg-color !important;
    margin: 0;
    padding: 5px 9px;
    font-weight: normal;
    color: #fff !important;
    box-shadow: none;

    &:not([disabled]) {
        &:not(.disabled) {
            &:active {
                background-color: $btn-grey-bg-color !important;
                box-shadow: none;
            }
        }

        &:not(.disabled).active {
            background-color: $btn-grey-bg-color !important;
            box-shadow: none;
        }
    }
}

.btn-white {
    font-size: 12px;
    color: #000 !important;
    background-color: #fff;
    margin: 0;
    padding: 18px 42px;
    border: 1px solid #000;
    font-weight: 300;
    box-shadow: none;
    cursor: pointer;

    &:not([disabled]) {
        &:not(.disabled) {
            &:active,
            &:focus {
                background-color: #fff !important;
                box-shadow: none;
            }
        }

        &:not(.disabled).active {
            background-color: #fff !important;
            box-shadow: none;
        }
    }
}

.btn-success {
    background-color: $btn-success-bg-color !important;
}

.modal {
    overflow-y: auto;

    &.bottom {
        position: static;

        .modal-dialog {
            position: fixed;
            z-index: 100;
        }
    }
}

.modal-close {
    position: absolute;
    right: 0;
    cursor: pointer !important;

    &:after {
        content: '';
        background-image: image('close-white.svg');
        height: 25px;
        width: 25px;
        background-size: contain;
        position: absolute;
        z-index: 10;
        left: 30px;
        bottom: -10px;
    }

    &--grey {
        &:after {
            background-image: image('close-grey.svg');
        }
    }
}

input.form-control {
    &:focus {
        + label {
            color: $font-main-grey !important;
        }
    }

    + label {
        &.active {
            color: $font-main-grey !important;
        }
    }

    &:focus {
        box-shadow: 0 1px 0 0 $input-label-active-color !important;
        border-bottom: 1px solid $input-label-active-color !important;
    }
}

/**
 * Basic styles for links
 */
a {
    color: $font-main-grey;
    text-decoration: none;

    @include on-event {
        color: $font-main-grey;
        text-decoration: underline;
    }
}

.form-control {
    font-size: 20px;
}

.md-form {
    label {
        font-size: 20px;
    }
}

form {
    &.form {
        &--white-text {
            label {
                color: #fff;
            }

            .form-control {
                color: #fff;
            }
        }
    }

    input {
        &.is-invalid {
            &:focus {
                border-color: $form-error-color !important;
                box-shadow: 0 1px 0 0 $form-error-color !important;
            }

            border-color: $form-error-color !important;

            + label {
                color: $form-error-color !important;
            }
        }
    }

    .invalid-feedback {
        text-align: left;
        font-size: 15px;
        color: $form-error-color;
    }
}

.form-check-input[type='checkbox'].filled-in {
    &:checked {
        & + label {
            &:before {
                border: none;
                display: none;
            }

            &:after {
                background: image('checkbox-checked.svg') no-repeat;
                border: none;
                margin: 5px;
            }
        }
    }

    &:not(:checked) {
        & + label {
            &:after {
                background: image('checkbox.svg') no-repeat;
                border: none;
                margin: 5px;
            }
        }
    }
}

label.btn {
    input[type='checkbox'].filled-in {
        &:checked {
            & + label {
                &:before {
                    border: none;
                    display: none;
                }

                &:after {
                    background: image('checkbox.svg') no-repeat;
                    border: none;
                    margin: 5px;
                }
            }
        }

        &:not(:checked) {
            & + label {
                &:after {
                    background: image('checkbox.svg') no-repeat;
                    border: none;
                    margin: 5px;
                }
            }
        }
    }
}

.inline-message {
    &__error {
        color: #fff;
    }
}

@-webkit-keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@-moz-keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.view-mode-container {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    justify-content: flex-end;

    &.list {
        justify-content: space-between;
    }

    .with-image-checkbox {
        flex-grow: 0;
        min-width: 190px;
        border-radius: 7px;
        background-color: #f3f3f3;
        padding: 15px 8px !important;

        label {
            margin-right: 0px !important;
            font-size: 12px;
            text-transform: uppercase;
            font-family: 'General Sans', sans-serif !important;
            font-weight: 500;
            color: #000;
        }

        .form-check {
            margin-bottom: 0px !important;
        }
    }
}

.view-mode-wrapper {
    padding-left: 25px;
    border-left: #3b4151 1px solid;

    .preferences {
        padding-right: 0;
        color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        font-family: 'General Sans', sans-serif !important;
        margin-bottom: 5px;
    }

    &.bottom-type {
        padding-left: 0;
        border-left: none;
        background-color: #000;
        padding: 10px 18px;
        gap: 21px;
        border-radius: 7px;
        margin-bottom: 33px;
    }
}

.lnk-filter-reset {
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: bold;
    background-color: #fff !important;
    color: $font-main-grey !important;
    box-shadow: none;
}

.lnk-filter-reset:hover,
.lnk-filter-reset:active,
.lnk-filter-reset:focus,
.lnk-filter-reset:visited {
    text-decoration: none !important;
    box-shadow: none !important;
    background-color: #fff !important;
}

.exhibition-wrapper button {
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
    font-family: 'General Sans', sans-serif !important;
    background-color: #ff0c0c !important;
    padding: 22px;
}

.download-btn button.btn-pdf {
    background-color: #ff0c0c !important;
    height: 28px;
    border-radius: 3px !important;
    font-size: 16px;
    font-weight: 700 !important;
    width: 40px !important;
    padding-top: 0.4rem !important;
}

.download-btn button.btn-excel {
    background-color: #3ea000 !important;
    height: 28px;
    border-radius: 3px !important;
    font-size: 16px;
    font-weight: 700 !important;
    width: 40px !important;
    padding-top: 0.2rem !important;
}
