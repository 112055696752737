.account-dropdown-menu {
    .dropdown-toggle {
      &:after {
        display: none;
      }
    }

    .dropdown-menu {
      width: 290px;

      hr {
        margin: 1rem 25px;
        border-color: $main-dark-grey;
      }
    }

  &__icon{
    width: 19px;
    height: 19px;
    margin-right: 10px;
  }
}
