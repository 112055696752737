.new-password {
    height: 100% !important;
    &__title {
        font-size: 28px;
        margin-bottom: 18px;
        font-weight: 400;
    }

    &__email {
        font-size: 20px;
        margin-bottom: 35px;
        font-weight: 400;
    }

    &__disclaimer {
        font-size: 15px;
        margin-bottom: 57px;
    }
}
