@use "sass:math";

.popover-badge {
  text-align: center;
  &__badge {
    padding: 6px 10px;
    font-size: 15px;
    font-weight: normal;
    box-shadow: none;
    border: none;
    border-radius: 0;

    &--promotion,
    &--information {
      border-radius: 50%;
      font-size: 13px;
      width: 20px;
      height: 20px;
      padding: 1px 0 0 1px;
    }
  }

  &__popover {
    padding: 10px 15px;
    color: #FFFFFF;
    text-align: left;

    &__header {
      font-size: 18px;
    }

    &__body {
      font-size: 14px;
      font-weight: lighter;
    }
  }
}
.popover-badge-promotion {
  .popover-badge {
    &__popover {
      &__header {
        font-size: 17px;
      }
    }
  }
}

.tooltip {
  &.show {
    opacity: 1;
  }
  .tooltip-inner {
    padding: 0;
    border-radius: 0;
    box-shadow: 0 0 11px 0 rgba(0,0,0,0.50);
    font-size: 18px;
  }
}

.on-hold-information-badge-wrapper {
  .tooltip-inner {
    max-width: 400px;
  }
}


.bs-tooltip-left {
  padding: 0 $popover-badge-arrow-height;

  .arrow {
    right: 0;
    width: $popover-badge-arrow-height;
    height: $popover-badge-arrow-width;

    &::before {
      left: 0;
      border-width: math.div($popover-badge-arrow-width, 2) 0 math.div($popover-badge-arrow-width, 2) $popover-badge-arrow-height;
    }
  }
}

.badge-campaign {
  padding: 6px 10px;
  font-size: 15px;
  font-weight: 400;
  border: none;
  border-radius: 0;
}