.my-order-list-info {
    &__checkbox-wrapper {
        margin-top: 46px;
        font-size: 16px;

        label {
            margin-right: 1.5rem !important;
        }
    }
}

.cart__make-order {
    background-color: #e7672b !important;
    padding: 25px 30px;
    margin-top: 100px;
    margin-bottom: 100px;
    font-weight: normal;
    cursor: pointer;
    font-size: 25px;
}

.cart-page {
    margin-top: 200px;

    .ReactTable .rt-thead {
        border: none;

        &.-header .rt-th {
            font-weight: 300;
        }
    }

    .table-preferences {
        border-top: 1px solid #505050;
        border-bottom: 1px dotted #505050;
    }

    &__heading-block {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        .title-page {
            font-size: 30px !important;
            color: #000;
        }

        .subtitle-page {
            font-size: 20px !important;
            font-family: 'Halvetica Neue', sans-serif;
            color: #707070;
            width: max-content;
            display: flex;
        }

        .price {
            font-weight: 600;
            color: #000;
            margin-left: 8px;
        }

        h3,
        p {
            font-weight: bolder;
            margin: 0;
        }

        h3 {
            font-size: 28px;
            font-weight: bold;
        }

        @media (max-width: 768px) {
            display: block;
        }
    }

    .cart {
        //border-bottom: 1px dashed $font-main-grey;
        //margin-bottom: 80px;
        padding: 12px 0px 40px;

        @media (max-width: 820px) {
            padding: 12px 4px 40px;
        }

        animation-duration: 0.3s;
        position: relative;

        &__order-date {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 11px;
            display: inline-block;
            color: #000;
        }

        &__change-date {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 11px;
            margin-left: 10px;
            text-decoration: underline;
            color: $main-pink;
            display: inline-block;

            &__date {
                &:hover {
                    cursor: pointer;

                    &:after {
                        content: '\f044';
                        font-family: 'FontAwesome';
                        margin-left: 8px;
                        font-size: 22px;
                        position: absolute;
                    }
                }
            }

            .fair-subcomponent {
                display: flex;
                flex-wrap: nowrap;
                text-align: center;
                color: #505050;

                &.even {
                    background: transparent;
                }

                &.odd {
                    background-color: #ffb266;
                }

                .md-form {
                    width: 80px;
                }

                .fair-subcomponent-week {
                    width: 12.5%;
                    padding-bottom: 5px;
                    padding-top: 5px;

                    span {
                        margin-right: 10px;
                    }

                    input {
                        border: 1px solid #505050;
                        border-radius: 3px;
                        text-align: center;
                    }
                }
            }
        }

        &__header {
            display: flex;
            margin-bottom: 10px;

            .campaign-stickers {
                margin-left: auto;
            }
        }

        &__subtotal {
            text-align: right;
            font-size: 24px;
            font-weight: bold;
        }

        &__footer {
            display: flex;
            flex-direction: column;

            .form-group {
                margin: 0;
            }

            &__not-container {
                display: flex;
                justify-content: space-between;
                margin-top: 35px;
            }

            &__notification {
                display: flex;
                justify-content: space-evenly;
                align-items: flex-start;
                padding: 37px;
                max-width: 620px;
                gap: 45px;
                height: 233px;
                border-radius: 4px;

                &--red {
                    background-color: #b60202;
                }

                &--green {
                    background-color: #36b714;
                }

                &__texts {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                }

                p {
                    color: #f0faff;
                    font-weight: 500;
                    font-size: 20px;
                    margin: 0 !important;
                }

                p:nth-child(1) {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: bold;
                }

                p:nth-child(2) {
                    font-weight: bold;
                    margin-bottom: 0;
                    color: #fff;
                    font-size: 25px;
                }

                p:nth-child(3) {
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            &__controls {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                flex-direction: column;

                &__cart-remove {
                    cursor: pointer;
                    display: inline-block;
                    font-size: 15px;
                    margin: 0 24px 0 auto;

                    img {
                        width: 24px;
                        margin-bottom: 4px;
                    }
                }

                button {
                    margin-right: -1px;
                    width: 208px;
                    padding: 0;
                    border-radius: 0px;
                    margin-left: 14px;
                    height: 70px !important;
                    box-shadow: none;

                    p {
                        margin: 0 !important;
                    }
                }

                &--red button {
                    background: linear-gradient(
                        180deg,
                        $main-orange 0%,
                        $main-pink 100%
                    ) !important;
                }

                &--green button {
                    background: linear-gradient(
                        180deg,
                        $main-orange 0%,
                        $main-pink 100%
                    ) !important;
                }
            }
        }

        &__loader {
            &--full {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 10000;
                background: rgba(255, 255, 255, 0.6);
            }
        }

        &__price-description {
            text-align: right;
            font-size: 12px;
        }

        &__header-wrapper {
            @media (max-width: 820px) {
                display: block !important;
                margin-bottom: 10px;
            }
        }

        @media (max-width: 820px) {
            &__footer__controls {
                display: block;
                text-align: center;

                &__btn-wrapper {
                    margin-top: 10px;
                    display: block !important;
                }
            }
        }
    }

    .continue-order-button {
        height: 62.88px !important;
    }

    .buttonOrder {
        height: 62.88px !important;
    }

    .continue-order-button:hover {
        color: white;
    }

    .update-order-button {
        background-color: #007bff !important;
        color: black !important;
        height: 62.88px !important;
    }

    .updatePriceButtonText {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .order-button {
        p {
            font-family: 'Anton', sans-serif;

            &:first-of-type {
                font-size: 20px;
                font-weight: 400;
                line-height: 1;
                margin-bottom: 7px;
                margin-top: 4px;
            }

            &:nth-of-type(2) {
                font-size: 20px;
                font-weight: 400;
                line-height: 1;
            }
        }
    }

    .order-number-input {
        width: 316px;
        display: inline-block;
        margin-right: auto;
    }

    .banner-text-no-price {
        padding: 10px 20px 10px 20px;
    }

    .campaign-stickers {
        @media (max-width: 820px) {
            margin-top: 15px;
        }
    }
}
