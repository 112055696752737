.notifications-page {
    &__mark-as-read {
        font-size: 12px;
        padding: 8px 10px;
    }

    &__table-title {
        font-weight: normal;
        font-size: 28px;
        margin-bottom: 17px;
    }

    .rt-td {
        button {
            background: linear-gradient(
                180deg,
                #f08200 0%,
                #eb5087 100%
            ) !important;
        }
    }

    &__unread {
        margin-bottom: 112px;
    }
    .table__rt__message {
        text-align: left;

        a {
            text-decoration: underline;
            white-space: pre-wrap;
        }
        span {
            white-space: pre-wrap;
        }
    }

    .ReactTable {
        .rt-tbody {
            .rt-tr {
                cursor: default;
            }
        }
    }
}
