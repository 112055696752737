.dashboard__notification {
    background: #99491e;
    border-radius: 8px;
    font-size: 20px;
    font-weight: normal;
    color: $dashboard-notification-color;
    margin: 0 auto 20px;
    display: flex;
    position: relative;
    animation-duration: 0.3s;

    &__url {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
    }
    &__close {
        min-width: 0;
        flex: 0 8.9%;
        align-items: center;
        display: flex;
        justify-content: center;

        img {
            width: 10px;
            position: relative;
            z-index: 20;
            cursor: pointer;
        }
    }

    &__body {
        min-width: 0;
        text-align: left;
        padding: 25px;
        padding-left: 35px;
        flex: 0 82.3%;
    }

    &__arrow {
        min-width: 0;
        background-color: rgba(48, 62, 61, 0.4);
        flex: 0 8.9%;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}
