.check-email {
    font-size: 15px;

    &__title {
        font-size: 28px;
        font-weight: 400;
    }

    &__instructions {
        margin-bottom: 33px;
    }

    &__contact {
        margin-bottom: 60px;
    }
}
