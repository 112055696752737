.downloads {
  .search {
    margin-top: 33px;
  }

  hr {
    border-top: 1px dotted #000;
    margin-bottom: 48px;
  }

  &__breadcrumbs {
    font-weight: normal;
    font-size: 14px;
    margin-left: 11px;
    cursor: pointer;
  }

  &__files, &__directories {
    display:-ms-grid;
    display: grid;
    overflow: hidden;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }

  .item-box {
    display: flex;

    &:hover {
      box-shadow: none;
      background-color: rgba(48, 62, 61, .85);
      color: white;

      .item-box{
        &__image-wrapper, &__image, &__image--hover {
          background-color: #4D5958;
        }

        &__image {
          &--hover {
            opacity: 1;
          }
        }
      }

      &.card {
        .card-body {
          .card-text {
            color: white;
          }
        }
      }
    }

    &__image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .image-file {
        width: 100%;
        height: 100%;
      }
    }

    &__image {
      margin-bottom: 0;

      &--hover {
        opacity: 0;
        background-color: #4D5958;
      }
    }
  }
}