.recipes__list {

  .title-header {
    &__title {
      margin-bottom: 47px;
    }
  }

  &--with-search {
    .title-header {
      &__title {
        margin-bottom: 30px;
      }
    }

    .search {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  &__go-back {
    text-decoration: underline;
    font-size: 16px;

    + p {
      margin-top: 10px;
    }
  }

  &__favourites {
    .favourite-icon {
      &__icon {
        margin-right: 10px;
      }
    }
  }
}