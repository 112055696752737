.security {
    background: linear-gradient(180deg, #f08200 0%, #eb5087 100%);
    width: 100vw;
    min-height: 100vh;
    height: max-content;
    text-align: center;
    color: #fff;

    &__logo {
        margin-top: 55px;
        margin-bottom: 62px;
    }

    .btn-brand {
        width: max-content;
        min-width: 165px;
        margin: 0;
        margin-top: 32px;
        font-family: 'General Sans', sans-serif;
        font-size: 22px;
        font-weight: 500;
        background: linear-gradient(
            180deg,
            #f08200 0%,
            #eb5087 100%
        ) !important;
        border: 1px solid #fff;
        border-radius: 6px;
    }

    &__url {
        color: #fff;
        text-decoration: underline;
        font-size: 14px;

        &:hover,
        &:focus {
            color: #fff;
        }
    }

    input {
        &.form-control {
            &:focus {
                + label {
                    color: $input-label-active-color !important;
                }
            }

            + label {
                &.active {
                    color: $input-label-active-color !important;
                }
            }
        }
    }
}
