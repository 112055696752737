.delivery-datepicker {
  &__title {
    &--global {
      position: absolute;
      top: 134px;
      left: 0;
      right: 0;
      z-index: 1332;
      font-size: 30px;
      text-align: center;
    }
  }
}