.allergens {
    &__table-header {
        margin-bottom: 25px;

        &__disclaimer {
            &__btn {
                cursor: pointer;
                color: $brand-color;
                display: inline-block;
                margin-left: 6px;
                font-size: 13px;
                line-height: 2;
            }
        }

        &__col {
            display: inline-block;

            &:first-of-type {
                margin-right: 35px;
            }
        }

        &__legend {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &__icon {
                margin-right: 5px;
            }

            span {
                &:not(.allergens__table-header__legend__icon) {
                    margin-right: 25px;
                }

                &:first-of-type {
                    &:before,
                    &:after {
                        right: -2px;
                        bottom: -1px;
                    }
                }

                &:last-of-type {
                    margin-right: 11px;
                }
            }
        }
    }

    .ReactTable {
        .rt-tbody {
            .rt-tr-group {
                border-bottom: none;
            }
        }

        @media (max-width: 1199px) {
            &.table {
                &__rt {
                    &--fixed-header {
                        > .rt-table {
                            > .rt-thead {
                                &.-header {
                                    position: static;

                                    + .rt-tbody {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .table {
        .rt-th {
            &.table__rt__checked-by-franky,
            &.table__rt__allergen--last {
                border-right: 1px solid $allergens-light-grey-border-color !important;
            }
        }

        &__rt {
            &--fixed-header {
                > .rt-table {
                    > .rt-thead {
                        &.-header {
                            position: sticky;
                            top: 0 !important;
                            backface-visibility: visible;

                            + .rt-tbody {
                                margin-top: 0 !important;
                            }
                        }
                    }
                }
            }

            &__product-name,
            &__allergen,
            &__kcal,
            &__kj {
                border-right: 1px solid $allergens-light-grey-border-color !important;
            }

            &__allergen {
                align-items: center;
            }

            &__checked-by-franky {
                border-right: 2px solid $main-dark-grey !important;
                align-items: center;
            }

            &__kcal {
                border-left: 2px solid $main-dark-grey !important;
            }

            &_col {
                &--hovered {
                    background-color: $brand-color-table-hover !important;

                    &.table__rt__allergen {
                        &:hover {
                            background-color: #f5cfbd !important;
                        }
                    }
                }
            }
        }

        .rt-thead {
            &.-headerGroups {
                display: none;
            }

            &.-filters {
                .filters-title {
                    max-width: 1105px;
                }

                .rt-th {
                    border-right: none !important;
                    border-left: none !important;
                }
            }
        }
    }

    &__not-contain {
        position: relative;

        &:before,
        &:after {
            position: absolute;
            content: '';
            width: 13px;
            height: 2px;
            background-color: $main-dark-grey;
            right: -6px;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    &__can-contain {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 12px solid red;
    }

    &__contain {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background-color: green;
    }

    .card-popup {
        max-width: 210px;

        &__body {
            white-space: normal;

            &__description {
                margin-bottom: 25px;
                font-weight: 300;
                font-size: 12px;
            }
        }
    }
}
