.oscart {
  &__left-section {
    padding: 20px 40px 40px 55px;
    background-image: image('oscart/smiles-bg.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    margin-top: 20px;

    &__description {
      font-size: 45px;
      color: $oscart-blue;
      margin-top: 110px;
      margin-bottom: 20px;
      max-width: 660px;
      line-height: 1.1;
      font-family: 'Cervo Medium';
      letter-spacing: 3px;
    }

    &__sub-description {
      font-family: 'nimbus_sans_lregular';
      max-width: 575px;
      font-size: 18px;
      color: $oscart-blue;
    }

    &__button {
      background-color: $oscart-blue;
      box-shadow: none;
      border-radius: 4px;
      font-size: 20px;

      &:after {
        content: '\f061';
        font-family: 'FontAwesome';
        position: relative;
        left: 10px;
      }

      &:hover {
        color: white;
        box-shadow: none;
      }
    }

    &__footer {
      position: absolute;
      bottom: 20px;
      width: 100%;
      text-align: center;
       
      @media (max-width: 768px) {
        display: flex;
      }

      img {
        float: left;
      }

      &__partner {
        color: $oscart-red;
        font-size: 30px;
        text-align: center;
        position: relative;
        top: 24px;
        right: 50px;
        font-family: 'Proxima Nova Soft Bold';

        @media (max-width: 1260px) {
          font-size: 20px;
          right: 23px;
        }

        @media (max-width: 768px) {
          font-size: 16px;
          left: 15px;
          width: 200px;
        }
      }
    }
  }

  &__right-section {
    background-color: $oscart-red;
    background-image: image('oscart/circle.png');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 470px;
    color: white;
    padding-right: 43px;
    padding-bottom: 40px;

    &__circle {
      &__wrapper {
        padding-top: 99px;
        overflow: auto;
      }

      &__text {
        font-size: 24px;
        line-height: 1;
        text-align: center;
        float: right;
        margin-right: 80px;
        font-family: 'Cervo Medium';
        letter-spacing: 1px;
      }
    }

    &__lets-meet {
      font-family: 'Proxima Nova Soft Bold';
      font-size: 60px;
      text-align: right;
      margin-top: 277px;
      line-height: 0.8;
    }

    &__address {
      text-align: right;
      margin-top: 64px;
      margin-bottom: 26px;
      font-size: 26px;
      line-height: 1;
      font-family: 'Cervo Medium';
      letter-spacing: 1px;
    }

    &__contact {
      font-size: 26px;
      text-align: right;
      line-height: 1;
      font-family: 'Cervo Medium';
      letter-spacing: 1px;
    }

    &__site {
      font-size: 35px;
      text-align: right;
      line-height: 1.1;
      margin-top: 20px;
      font-family: 'Cervo Medium';
      letter-spacing: 1px;

      a {
        color: white !important;
        text-decoration: none;

        &:hover, &:active, &:focus {
          color: white;
          text-decoration: none;
        }
      }
    }

    p {
      margin-bottom: 0;
    }
  }
}