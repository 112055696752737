.order-history-week {
  margin: 0 3px;
  font-weight: bold;
  color: black;
  min-width: 11px;

  .order-history-name {
    color: #808080;
    font-size: 10px;
  }

  .order-history-current {
    height: 10px;
    font-size: 7px;
    font-weight: bold;
  }

  .order-history-quantity {
    font-size: 13px;
  }

  &.order-history-is-current {
    .order-history-name {
      color: black;
    }
  }
}
