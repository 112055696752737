.favourite-icon {
    &__icon {
        position: relative;
        transform: scale(1.5);
        z-index: 10;
        cursor: pointer;
        border-radius: 50%;
        display: inline-block;
        color: #aaa;
        transition: 0.2s;
        /*margin-bottom: 10px;*/

        &:hover {
            color: #666;
        }

        &:before {
            font-family: FontAwesome;
            content: '\f08a';
            font-style: normal;
        }
    }

    &--is-favourite {
        animation: fadeIn 0.4s;
        color: #e23b3b;

        &.favourite-icon__icon {
            &:hover {
                color: #e23b3b;
            }

            &:before {
                font-family: FontAwesome;
                content: '\f004';
                font-style: normal;
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        color: transparent;
    }
    100% {
        color: #e23b3b;
    }
}

@keyframes size {
    0% {
        padding: 10px 12px 8px;
    }
    50% {
        padding: 14px 16px 12px;
        margin-top: -4px;
    }
    100% {
        padding: 10px 12px 8px;
    }
}
