.green-check {
  position: relative;

  &:after {
    content: ' ';
    background: image('check-green.svg') no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}