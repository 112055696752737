.modal-backdrop.show {
  opacity: .9 !important;

}

.promotional-modal {
  max-width: 70%;
  margin-top: 110px;
  padding-left: 80px;
  padding-right: 80px;

  @media (max-width: 1300px) {
    max-width: 80%;
  }

  &__close-footer {
    position: absolute;
    bottom: -50px;
    text-align: center;
    width: 100%;
    color: white;
    font-weight: normal;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .modal-body {
    padding: 8px
  }

  &__item {
    &--simple {
      img {
        width: 100%;
      }
    }
  }

  .boxes-promotion {
    padding: 39px 52px 39px 52px;
    flex: auto;

    &--multiple {
      padding: 29px 32px 29px 32px;
      display: flex;
      .promo-box {
        max-width: 600px;
        padding: 10px;
        margin-right: 25px;
        border: 1px solid #ccc;
        width: calc(33.3333% - 25px);
        display: flex;
        flex-wrap: wrap;

        &:last-child {
          margin-right: 0;
        }
        &__title {
          font-size: 22px;
        }

        img {
          max-height: 200px;
        }
      }
    }
  }

  .promo-box {
    max-width: 920px;
    &__title {
      text-transform: uppercase;
      text-align: center;
      font-size: 36px;
      font-weight: normal;
    }

    &__body {
      display: flex;
      flex-wrap: wrap;
      .quantity {
        img {
          display: inline !important;
        }

        &__input {
          background-color: white;
          border: 1px solid #D2D2D2;
          border-radius: 3px;
        }
      }
    }
  }

  &--carousel {
    .carousel-indicators {
      bottom: -110px;
    }

    .carousel-control-prev-icon, .carousel-control-next-icon {
      height: 79px;
      width: 87px;
    }
    .carousel-control-prev {
      opacity: 1;
      left: -147px;

      @media (max-width: 1000px) {
        left: -107px;
      }

      @media (min-width: 1700px) {
        left: -167px;
      }
    }

    .carousel-control-next {
      opacity: 1;
      right: -147px;

      @media (max-width: 1000px) {
        right: -107px;
      }

      @media (min-width: 1700px) {
        right: -167px;
      }
    }
  }

}