.filter-box {
  border-radius: 6px;
  border: 1px solid $border-grey;
  text-align: center;
  padding: 20px 50px 15px;
  max-width: 265px;
  background-color: #fff;

  &__name {
    font-size: 19px;
    margin-bottom: 1px;
  }

  &__items-count {
    font-size: 32px;
    margin-bottom: 1px;
    font-weight: normal;
  }

  button {
    border-radius: 3px;
    font-size: 12px;
    height: 30px;
    width: 60px;
    padding: 0 0.84rem;
    margin: 9px 3px 0 0;
  }

  &.__active {
    border: 2px solid #000;
  }
}