.card-popup {
  position: absolute;
  z-index: 99;
  box-shadow: 0 0 11px 0 rgba(0,0,0,0.50);
  border-radius: 0;
  font-size: 15px;
  min-width: 200px;
  margin-top: 5px;

  opacity: 0;
  top: 0;
  right: -5px;
  visibility: hidden;
  transition-duration: 200ms;
  transition-property: opacity, top, visibility;
  transition-delay: 0;

  &.show {
    opacity: 1;
    visibility: visible;
    top: 25px;
    transition-duration: 200ms;
    transition-property: opacity, top, visibility;
    transition-delay: 200ms;
  }

  &__close {
    position: absolute;
    right: 0;
    margin: 12px 8px 0 0;

    &:after {
      content: ' ';
      background: image('close-small-grey.svg') no-repeat;
      background-size: 9px 9px;
      padding: 4.5px 4.5px 0 5.3px;
      cursor: pointer;
    }
  }

  &__body {
    padding: 25px;
    white-space: nowrap;

    &__title {
      font-size: 18px;
      font-weight: bold;
    }
    &__list {
      padding: 0;
      font-weight: 300;

      li {
        list-style: none;
      }

      .form-check {
        padding-left: 0;
        margin-bottom: 0 !important;
        margin-top: 0 !important;


        .form-check-input[type="checkbox"] {
          &+label {
            height: 1.3625rem;;
          }
        }
        label.btn {
          input[type="checkbox"] {
            &+label {
              height: 1.3625rem;;
            }
          }
        }
      }

      &__childs {
        padding-left: 30px;
      }
    }
  }
}