.preloader-wrapper {
  &.brand-color {
    .spinner-layer {
      border-color: $brand-color;
    }
  }

  &.spinner {
    &--white {
      .spinner-layer {
        border-color: white;
      }
    }
    &--brand {
      .spinner-layer {
        border-color: $brand-color;
      }
    }
  }
}

.custom-spinner {
  width: 100%;
  height: 100%;
}