.delivery {
    .delivery-header {
        display: flex;
        padding: 52px 140px 32px 140px;
        align-items: center;
        color: #000;
        font-family: 'General Sans', sans-serif;
        font-weight: 500;

        .main-search-wrapper {
            width: max-content;
        }

        .search-fixed {
            position: static;
        }

        .search {
            margin-left: 75px;
            border: none;
        }
    }
    .table {
        &__rt {
            &__message {
                position: relative;
            }
            &__reference {
                text-align: left;
            }
        }

        .rt-tr-group {
            .rt-tr:not(:last-child) {
                border-top: 2px solid #000;
            }
        }

        .rt-tr {
            &.-odd {
                + .delivery__details {
                    background-color: #f4f4f4;
                }
            }
        }
    }

    &__table {
        margin-bottom: 130px;
    }

    &__table-title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 25px;

        &__wrapper--with-badge {
            display: flex;

            .delivery__table-title {
                margin-right: 10px;
            }

            .popover-badge__badge--information {
                padding: 1px 0 0 0;
            }
        }
    }

    &__status {
        text-align: left;
        padding-left: 16px;

        @each $status, $color in $delivery-status-colors {
            &--#{$status} {
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                background-color: $color;
                width: 8px;
                height: 100%;
            }
        }
    }

    &__documents {
        &__btn {
            &.btn.btn-sm {
                width: 60px;
                border-radius: 2px;
                margin: 0;
                margin-right: 3px !important;
                padding: 7px 0px;
                font-weight: 600;
            }
        }
    }

    &__details {
        padding-top: 56px;
        padding-left: 77px;
        padding-bottom: 37px;
        border-top: 1px dotted #000;
        border-bottom: 2px solid #000;

        &__data {
            margin-bottom: 40px;

            &__title {
                font-weight: normal;
            }

            &__table {
                &__header {
                    font-size: 10px;
                    text-transform: uppercase;
                }
            }

            .btn-retour {
                position: absolute;
                right: -220px;
                height: 20px;
                top: -4px;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    .modal-retour {
        .modal-body {
            padding: 0;
        }
        &__inputs {
            padding: 1rem;
        }
        &__dropdown-reason {
            padding-left: 0;
            padding-bottom: 0;
            width: 300px;
        }
        &__submit {
            padding: 6px;
            button {
                margin: 0;
                width: 100%;
            }
        }
        &__span-error {
            color: red;
            font-size: 9px;
        }
    }

    .modal-close:after {
        background-image: url('../images/close-grey.svg');
        height: 14px;
        width: 14px;
        right: 10px;
        top: 10px;
        left: auto;
    }
}
