.recipe__details {
  background-color: $recipe-details-bg-color;
  &__header {
    background-size: cover;
    background-position: center center;
    min-height: 180px;
    max-height: 556px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__image {
      &--print {
        display: none;
      }
    }

    &__body {
      position: absolute;
      bottom: 0;

      &__inner {
        background-color: $recipe-details-bg-color;
        min-height: 300px;
        width: 730px;
        position: relative;
        top: 181px;
        padding: 33px 87px;
        text-align: center;
      }

      &__title {
        font-weight: normal;
        margin-bottom: 30px;
        margin-top: 13px;
        font-size: 40px;
      }
    }
  }

  &__go-back {
    margin-top: 75px;
    z-index: 1;
    position: relative;
    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__body {
    margin-top: 100px;

    &__ingredients {
      &__content {
        background-color: $brand-color;
        padding: 33px 69px;
        color: #fff;
        font-size: 15px;

        &__list {
          padding-left: 18px;
        }

        &__title {
          font-weight: normal;
          font-size: 30px;
          margin-top: 55px;
          margin-bottom: 0;
        }
      }
    }

    &__instructions {
      &__title {
        font-weight: normal;
        font-size: 20px;
        color: #000;
        margin-bottom: 33px;
      }

      &__content {
        line-height: 25px;
        font-weight: 300;

        ol, ul {
          padding-left: 16px;
        }
      }
    }
  }
}

@media print {
  .recipe__details {
    &__header {
      background: none !important;
      height: 356px !important;

      &__image {
        &--print {
          display: block;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      &__body {
        &__inner {
          .favourite-icon {
            display: none;
          }

          button {
            display: none;
          }
        }
      }
    }

    &__go-back {
      display: none;
    }


    &__body {
      margin-top: 100px;
      &__ingredients {
        &__content {
          -webkit-print-color-adjust: exact;
        }
      }
    }
  }
  .footer {
    display: none;
  }

}
