.my-account {
  .title-header {
    margin-bottom: 40px;
  }

  &__email-clickable {
    color: $brand-color;
    text-decoration: underline;
  }

  &__representative {
    font-size: 20px;
    text-align: center;
    margin-bottom: 60px;
  }

  &__section-title {
    &__title {
      font-weight: normal;
      font-size: 26px;
    }

    hr {
      border-top: 5px solid #000;
      max-width: 100px;
      margin-left: 0;
      margin-top: 11px;
      margin-bottom: 36px;
      text-align: left;
    }
  }

  &__section {
    p {
      margin-bottom: 0;
    }
  }

  &__new-password {
    .form-group {
      margin-bottom: 8px;

      input {
        padding: 6px 13px;
        height: auto;

        /* CANNOT COMBINE THOSE SELECTORS AS THEY WONT WORK :( */
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $my-account-new-password-placeholder-text-color;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $my-account-new-password-placeholder-text-color;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: $my-account-new-password-placeholder-text-color;
        }
      }
    }

    &__buttons {
      margin-top: 16px;
      button {
        width: 100%;
        margin: 0;
      }
    }
  }

  &__incorrect-data {
    margin-top: 30px;
    margin-bottom: 150px;
  }

  &__departments-access {
    font-size: 20px;

    &__info {
      margin-bottom: 40px;
    }

    &__department {
      margin-bottom: 40px;
      height: 50px;

      &__name {
        text-transform: uppercase;
        padding-top: 11px;
      }

      &__password {
        input {
          height: 50px;
        }

        span {
          position: relative;
          left: 14px;
          top: 10px;
        }
      }

      &__btn {
        text-align: center;
        button {
          height: 100%;
          margin: 0 10px 0 0;
        }

        img {
          cursor: pointer;
        }

        &__edit {
          cursor: pointer;
          width: 25px;
          margin-right: 10px;
        }
      }
    }

    &__incorrect-data {
      font-size: 15px;
    }
  }

}