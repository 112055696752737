.product-type-filter {
  background: white;
  border-radius: 2.25px;
  padding: 12px 20px;
  color: $font-main-grey;
  font-weight: normal;
  text-align: center;
  border: 1px solid #E4E4E4;
  display: flex;
  cursor: pointer;

  &__label {
    font-size: 15px;
    margin-bottom: 0;
    display: inline-block;
  }

  &__download_pdf {
    &__icon {
      width: 24px;
      margin-left: 12px;
      display: inline-block;
      height: 24px;
      border-left: 1px solid #E4E4E4;

      &:before {
        content: '';
        background-image: image('download-arrow-grey.svg');
        width: 24px;
        height: 24px;
        display: block;
        background-size: contain;
      }
    }
  }

  &--loading {
    .product-type-filter {
      &__label {
        > div { //LOADING DIV
          margin-left: 10px;
        }
      }
    }
  }

  &--active {
    background-color: $brand-color;
    border-radius: 2.25px;
    padding: 12px 20px;
    font-weight: normal;
    color: white;
    cursor: pointer;

    a {
      color: white
    }

    span {
      color: white;

      &:hover {
        text-decoration: none;
      }
    }

    &__label {
      padding-right: 20px;
      border-right: 1px solid white;
    }

    .product-type-filter__download_pdf {
      &__icon {
        border-left: 1px solid white;

        &:before {
          background-image: image('download-arrow-white.svg');
        }
      }
    }
  }
}