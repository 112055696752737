.fair-supplier-select {
  max-width: 280px;
  margin: 0;
  

  &.select-wrapper {
    span.caret {
      top: 18px;
      right: 15px;
      @media(max-width: 991px) {
        right: 0px;
      }
    }
  }

  .fair-supplier-select__input {
    max-width: 256px;

    &.select-dropdown {
      border: 1px solid #ddd;
      border-radius: 6px;
      padding-left: 22px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 16px;
    }
  }

  &__options {
    li {
      > span {
        color: $font-main-grey;
      }
    }
  }
}