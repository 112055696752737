.campaign {
    &-header {
        display: flex;
        padding: 65px 140px;
        align-items: center;
        color: #000;
        font-family: 'General Sans', sans-serif;
        font-weight: 500;
    }

    &__item {
        background-color: #fff;
        box-shadow: 5px 5px 10px #00000029;
        border: 1px solid #ececec;
        padding: 20px;
        margin-bottom: 32px;
        border-radius: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__name-container {
            display: flex;
            flex-direction: row-reverse;
            padding-right: 100px;
        }

        &__name {
            font-family: 'Anton', sans-serif;
            color: #000000;
            text-align: right;
            font-size: 70px;
        }

        &__bottom-part {
            height: 100%;

            &__info {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &__top-title {
                    margin-top: 50px;
                }
            }
        }

        &__header {
            font-size: 36px;
            color: white;
            padding: 8px 11px 20px 11px;
            font-weight: normal;
            line-height: 1;
            margin-bottom: 43px;
        }

        &__left-column {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        &__center {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__top {
                display: flex;
                align-items: center;
            }
        }

        &__right-column {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__image-wrapper,
        &__btn-wrapper {
            flex-shrink: 0;
        }

        &__btn-wrapper {
            margin-top: 36px;

            a {
                border-radius: 6px;
                font-size: 18px;
                font-weight: 500;
            }
        }

        &__image-wrapper {
            flex-shrink: 0;

            img {
                width: 100%;
                margin-bottom: 10px;
            }
        }

        &__active-date {
            font-size: 18px;
            color: #000000;
            text-transform: capitalize;
        }

        &__variant {
            &__points,
            &__name {
                font-size: 24px;
                font-weight: normal;
            }

            &__redeem-btn {
                font-size: 12px;
                padding: 6px 13px;
                border-radius: 0;
                color: white !important;
                box-shadow: none;
            }
        }

        &__description {
            font-size: 18px;
            color: #000000;
            text-transform: capitalize;
        }

        &__points {
            border-radius: 50%;
            width: 177px;
            height: 177px;
            margin-left: 36px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: normal;

            &__label,
            &__points {
                margin-bottom: 0;
            }

            &__label {
                font-size: 18px;
                font-weight: 600;
            }

            &__points {
                font-size: 80px;
                line-height: 0.8;
                font-weight: 600;
            }
        }

        &__points.rectangle {
            border-radius: 0;
            width: 70px;
            margin-bottom: 6px;
        }

        &__pdf-image-container {
            img {
                width: 263px;
                height: auto;
                box-shadow: 5px 5px 5px #00000069;
            }
        }

        &__remark {
            font-size: 12px;
            margin-left: 32px;
            width: 180px;
            display: inline-block;
            text-align: left;
            line-height: 16px;
            color: #707070;
        }

        @media (max-width: 991px) {
            &__points,
            &__remark {
                margin-left: 0px;
            }
        }

        .btn-gradient {
            background: linear-gradient(
                180deg,
                $main-orange 0%,
                $main-pink 100%
            );
            border: none;
            color: white;
            font-size: 18px;
            padding: 10px 20px;
            border-radius: 6px;
            margin-top: 10px;
            box-shadow: none;
            border: 1px solid #ffffff6a;
            font-family: 'Anton', sans-serif;
            letter-spacing: 0.8px;
        }

        &__see-product-btn {
            font-size: 12px;
            background-color: $btn-grey-bg-color !important;
            font-weight: normal;
            padding: 5px 9px;
            border-radius: 0;
            margin: 0;
        }
    }

    &__stickers-title {
        font-weight: bold;
        margin-bottom: 5px;
        color: #000;
        font-size: 16px;
    }
}
